import { Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';

import { useTranslations } from 'next-intl';
import { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Service } from '../interfaces/services.interface';
import { ServicePreviewTabs } from '../enums/service-preview-tabs';
import { ServiceDetailsTab } from './service-details-tab';
import { CommentsList, toast, ToastAction, ToastTypeEnums } from '@/components/common';
import { AllShiftsTable } from '../../all-shifts/table/all-shifts-table';
import { useServiceQuery } from '@/hooks/fetchers/queries/services/useServiceQuery';
import { AllDocumentsTable } from '@/views/all-documents/table/all-documents-table';
import { useCommentsQuery } from '@/hooks/fetchers/queries/useCommentsQuery';
import { Dot } from 'lucide-react';
import { ManageServiceDialog, ManageServiceDialogRef } from '../manage/manage-service.dialog';
import { useRemoveServiceMutation } from '@/hooks/fetchers/mutations/services/useRemoveServiceMutation';
import { useRemoveUndoServiceMutation } from '@/hooks/fetchers/mutations/services/useRemoveUndoServiceMutation';

interface Props {
  onRemove?: (id: string) => void;
  onUpdated?: () => void;
}

export type PreviewServiceDialogRef = {
  open: (service: Service) => void;
  openById: (id: string, isCanManage?: boolean) => void;
};

const PreviewServiceDialog = forwardRef<PreviewServiceDialogRef, Props>(({ onRemove, onUpdated }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const [serviceViaList, setServiceViaList] = useState<Service | null>(null);
  const manageServiceDialogRef = createRef<ManageServiceDialogRef>();
  const [serviceId, setServiceId] = useState<string>();

  const { data: serviceDataById, refetch: refetchService } = useServiceQuery(serviceId, {
    enabled: true,
    refetchOnWindowFocus: isOpen,
  });
  const { data: comments } = useCommentsQuery(serviceId, { refetchOnWindowFocus: isOpen });

  useImperativeHandle(ref, () => ({
    open: (service) => {
      setIsOpen(true);
      setServiceViaList(service);
      setServiceId(service.id);
    },
    openById: (id) => {
      setIsOpen(true);
      setServiceId(id);
    },
  }));

  useEffect(() => {
    if (!isOpen && serviceId) {
      setServiceId(undefined);
    }
  }, [isOpen]);

  const service = serviceDataById ? serviceDataById : serviceViaList;

  const { mutate: removeService } = useRemoveServiceMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndoService(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      setIsOpen(false);
      onUpdated?.();
      onRemove?.(data.id);
    },
  });

  const { mutate: removeUndoService } = useRemoveUndoServiceMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      onUpdated?.();
    },
  });

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-[1280px]">
          <DialogHeader>
            <DialogTitle>{t('Pages.AllServices.serviceDetails')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>

          <Tabs defaultValue={ServicePreviewTabs.INFO}>
            <TabsList className="w-full max-w-[500px]">
              <TabsTrigger className="w-full" value={ServicePreviewTabs.INFO}>
                {t('Buttons.details')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={ServicePreviewTabs.DOCUMENTS}>
                {t('Buttons.documents')}
              </TabsTrigger>
              <TabsTrigger className="w-full" value={ServicePreviewTabs.SHIFTS}>
                {t('Buttons.shifts')}
              </TabsTrigger>
              <TabsTrigger className="relative w-full" value={ServicePreviewTabs.COMMENTS}>
                {comments && comments.length > 0 ? (
                  <Dot className="absolute right-[-4px] top-[-4px] h-8 w-8 text-destructive" />
                ) : null}
                {t('Buttons.comments')}
              </TabsTrigger>
            </TabsList>
            <TabsContent value={ServicePreviewTabs.INFO} className="pt-4">
              <div className="h-[calc(100vh-260px)]">
                {service && (
                  <ServiceDetailsTab
                    onRefresh={() => {
                      setIsOpen(false);
                      onUpdated?.();
                    }}
                    onEdit={() => manageServiceDialogRef.current?.open(service)}
                    service={service}
                  />
                )}
              </div>
            </TabsContent>

            <TabsContent value={ServicePreviewTabs.DOCUMENTS}>
              <div className="h-[calc(100vh-228px)]">
                {service && (
                  <AllDocumentsTable
                    tableClassName="h-[calc(100vh-376px)]"
                    serviceId={service.id}
                    hideCreate={false}
                    hideTableSettings
                  />
                )}
              </div>
            </TabsContent>
            <TabsContent value={ServicePreviewTabs.SHIFTS}>
              <div className="h-[calc(100vh-228px)]">
                {service && (
                  <AllShiftsTable
                    tableClassName="h-[calc(100vh-368px)]"
                    serviceId={service.id}
                    hideCreate={false}
                    hideTableSettings
                  />
                )}
              </div>
            </TabsContent>
            <TabsContent value={ServicePreviewTabs.COMMENTS}>
              <div className="h-[calc(100vh-236px)]">
                {service && <CommentsList scrollAreaClassName="h-[calc(100vh-308px)]" serviceId={service.id} />}
              </div>
            </TabsContent>
          </Tabs>
        </DialogContent>
      </Dialog>
      <ManageServiceDialog
        onUpdated={refetchService}
        onDelete={(id) => removeService(id)}
        ref={manageServiceDialogRef}
      />
    </>
  );
});

PreviewServiceDialog.displayName = 'PreviewServiceDialog';

export { PreviewServiceDialog };
