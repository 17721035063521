'use client';

import { Input } from '@/components/form';
import { cn } from '@/lib/utils';
import lodash from 'lodash';
import { SearchIcon } from 'lucide-react';
import { useTranslations } from 'next-intl';
import { ChangeEvent, useCallback } from 'react';

interface Props {
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  placeholder?: string;
}

export function SimpleSearch({ onSearchChange, className, placeholder }: Props) {
  const t = useTranslations();

  const onChangeSearchHandler = useCallback(lodash.debounce(onSearchChange, 300), []);

  return (
    <Input
      className={cn('w-80', className)}
      leftIcon={SearchIcon}
      onChange={onChangeSearchHandler}
      placeholder={placeholder ? placeholder : t('Forms.search.placeholder')}
    ></Input>
  );
}
