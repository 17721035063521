import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { TableTypes } from '@/common/enums/common';

export interface Response {
  id: string;
  name: TableTypes;
  filters: any;
}

export type Options = Omit<UseQueryOptions<unknown, DefaultError, Response>, 'queryKey' | 'queryFn'>;

export const useTableFilterDefaultQuery = (table: TableTypes, options?: Options) => {
  const _query = async (): Promise<Response> => {
    // const { data } = await axiosInterceptorInstance.get(`table-filters-configuration`, {
    //   params: { name: table },
    // });
    // return data;
    return Promise.resolve(
      {
        id: '1',
        name: TableTypes.ALL_CLIENTS,
        filters: {}
      }
    )
  };

  return useQuery({
    queryKey: ['table-filters-configuration', table],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
