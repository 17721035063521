import { Therapist } from './interfaces/therapists.interface';

export interface PayRates {
  generalBasePayRate: number;
  payRates: { serviceRoleName: string; payRate: number; serviceRoleId: string }[];
}

// @TODO: Refactoring API, get normal response
// generalBasePayRate: number;
// payRates: { id: string; serviceRoleName: string; payRate: number; }
export const parsePayRates = (therapist: Therapist): PayRates | null => {
  if (therapist.servicePayRate && therapist.servicePayRate.length !== 0) {
    let payRates = [];
    const generalBasePayRate = therapist.servicePayRate.find((rate) => rate.type === 'default');

    payRates = therapist.servicePayRate.filter((rate) => rate.type !== 'default');
    payRates = payRates.map((rate) => ({
      ...rate,
      serviceRoleId: therapist.serviceRole?.find((sr) => sr.title === rate.serviceRoleName)?.id,
    }));

    return {
      generalBasePayRate: generalBasePayRate && generalBasePayRate.payRate ? generalBasePayRate.payRate : 0,
      payRates: payRates as { serviceRoleName: string; payRate: number; serviceRoleId: string }[],
    };
  } else if (therapist.serviceRole) {
    const payRates = therapist.serviceRole?.map((role) => ({
      serviceRoleName: role.title,
      payRate: 0,
      serviceRoleId: role.id,
    }));
    return {
      generalBasePayRate: 0,
      payRates,
    };
  }
  return null;
};
