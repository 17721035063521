export enum TypesOfShiftRepeat {
  SINGLE = 'Single',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export enum TableTypes {
  ALL_SHIFTS = 'all-shifts',
  MY_CLIENTS = 'my-clients',
  SIGNATURES = 'signatures',
  TIMESHEETS = 'timesheets',
  COLLAPSIBLE = 'collapsible',
  ALL_CLIENTS = 'all-clients',
  ALL_SERVICES = 'all-services',
  MY_DOCUMENTS = 'my-documents',
  ALL_DOCUMENTS = 'all-documents',
  ALL_GUARDIANS = 'all-guardians',
  SERVICE_ROLES = 'service-roles',
  ALL_THERAPISTS = 'all-therapists',
  ADMIN_USERS_LIST = 'admin-users-list',
  AVAILABLE_CLIENTS = 'available-clients',
  TIMESHEET_DETAILS = 'timesheet-details',
  DETAILED_CALCULATION = 'detailed-calculation',
}
