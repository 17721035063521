'use client';
import {
  ColumnOrderState,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ChangeEvent, createRef, useEffect, useRef, useState } from 'react';
import { Plus } from 'lucide-react';
import { ConfirmDialog, Pagination, toast, ToastAction, ToastTypeEnums } from '@/components/common';
import { Button, Dialog } from '@/components/ui';
import { ColumnAdvance, TableColumnSettings } from '../../../components/common/table/table-column-settings';
import { ConfirmDialogRef } from '@/components/common/confirm-dialog';
import { useTranslations } from 'next-intl';
import { clearFilter, parseFilterToArrayOfTagsData, sortOrderValueToAPIParams } from '@/components/common/table/utils';
import { CommonTable } from '@/components/common/table/common-table';
import { SimpleSearch } from '@/components/common/table/simple-search';
import { TableFilter } from '@/components/common/table/table-filter';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Client, Guardian, Service, Therapist } from '../interfaces/services.interface';
import { AllServicesFilterForm, FilterAllServicesParams } from '../header-filter/all-services-filter-form';
import { PreviewServiceDialog, PreviewServiceDialogRef } from '../preview/preview-service.dialog';
import { useServicesQuery } from '@/hooks/fetchers/queries/services/useServicesQuery';
import { useRemoveServiceMutation } from '@/hooks/fetchers/mutations/services/useRemoveServiceMutation';
import { useRemoveUndoServiceMutation } from '@/hooks/fetchers/mutations/services/useRemoveUndoServiceMutation';
import { getAllServicesColumns } from './all-services-columns';
import { CreateServiceForm } from '../manage/create-service-form';
import { useIsAllowed } from '@/lib/RBAC';
import { PermissionsTypes } from '@/lib/RBAC/enums/permissions-types';
import { cn } from '@/lib/utils';
import { STATES_TYPES } from '@/common/constants';
import { ToggleFilter } from '@/components/common/toggle-filter';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';
import { TableTypes } from '@/common/enums/common';
import { PreviewClientDialog, PreviewClientDialogRef } from '@/views/all-clients/preview/preview-client.dialog';
import {
  PreviewTherapistDialog,
  PreviewTherapistDialogRef,
} from '@/views/all-therapists/preview/preview-therapist.dialog';
import { PreviewGuardianDialog, PreviewGuardianDialogRef } from '@/views/all-guardians/preview/preview-guardian.dialog';
import { useTableFilterDefaultQuery } from '@/hooks/fetchers/queries/useTableFilterDefaultQuery';
import { useTableFilterDefaultMutation } from '@/hooks/fetchers/mutations/useTableFilterDefaultMutation';

const PER_PAGE = 100;

interface Params {
  clientId?: string;
  therapistId?: string;
  tableClassName?: string;
  hideCreate?: boolean;
  hideTableSettings?: boolean;
}

export function AllServicesTable({ clientId, therapistId, tableClassName, hideCreate, hideTableSettings }: Params) {
  const t = useTranslations();
  const [data, setData] = useState<Service[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [createServiceDialogOpen, setCreateServiceDialogOpen] = useState(false);
  const [filter, setFilter] = useState<FilterAllServicesParams>({});
  const [stage, setStage] = useState<STATES_TYPES>();
  const [search, setSearch] = useState('');
  const [showDeleted, setShowDeleted] = useState(false);
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });

  const previewServiceDialogRef = createRef<PreviewServiceDialogRef>();
  const previewClientDialogRef = createRef<PreviewClientDialogRef>();
  const previewGuardianDialogRef = createRef<PreviewGuardianDialogRef>();
  const previewTherapistDialogRef = createRef<PreviewTherapistDialogRef>();
  const confirmDialogRef = useRef<ConfirmDialogRef>();
  const { checkPermissions } = useIsAllowed();

  const { data: tableConfig } = useTableSettingsQuery(TableTypes.ALL_SERVICES);
  const { mutate: setTableConfig } = useTableSettingsMutation();
  const { data: tableDefFilters } = useTableFilterDefaultQuery(TableTypes.ALL_SERVICES);
  const { mutate: setTableDefFilters } = useTableFilterDefaultMutation();
  const { data: tableData, refetch: refetchServices, isLoading } = useServicesQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    search,
    clientId,
    showDeleted,
    therapistId,
    ...filter,
    stage,
    ...sortOrderValueToAPIParams(sorting),
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<Service>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);

  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  useEffect(() => {
    if (filter) {
      setPagination((prev) => ({ ...prev, page: 0 }));
    }
  }, [JSON.stringify(filter)]);

  const { mutate: removeService } = useRemoveServiceMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndoService(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      refetchServices();
    },
  });

  const { mutate: removeUndoService } = useRemoveUndoServiceMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      refetchServices();
    },
  });

  const handleConfirmRemove = (row: Row<Service>) => {
    confirmDialogRef.current?.open({
      description: t('Toasts.confirmDeleteService', { name: `${row.original.title}` }),
      value: row.original.id,
    });
  };

  const handlePreviewClient = (client: Client) => {
    previewClientDialogRef.current?.openById(client.id);
  };

  const handlePreviewGuardian = (guardian: Guardian) => {
    previewGuardianDialogRef.current?.openById(guardian.id);
  };

  const handlePreviewTherapist = (therapist: Therapist) => {
    previewTherapistDialogRef.current?.openById(therapist.id);
  };

  const table = useReactTable({
    columns: getAllServicesColumns({
      t,
      onConfirmRemove: handleConfirmRemove,
      onPreviewClient: handlePreviewClient,
      onPreviewGuardian: handlePreviewGuardian,
      onPreviewTherapist: handlePreviewTherapist,
      checkPermissions,
    }),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
  });

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<Service>[]) => {
    setTableConfig({
      name: TableTypes.ALL_SERVICES,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  useEffect(() => {
    if (tableDefFilters && tableDefFilters.filters) {
      setFilter(tableDefFilters.filters);
    }
  }, [JSON.stringify(tableDefFilters)]);

  const handleSearchFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    const search = event?.target.value || '';
    setPagination((prev) => ({
      ...prev,
      page: 0,
    }));
    setSearch(search);
  };

  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<Service>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  const changeState = (state: STATES_TYPES) => {
    setStage((prev) => (prev === state ? undefined : state));
    setShowDeleted(false);
  };

  const changeArchivedFilter = () => {
    setShowDeleted((prev) => !prev);
    setStage(undefined);
  };

  return (
    <>
      <header className="flex flex-row items-center justify-between py-2">
        <TableFilter
          filter={filter}
          clearFilter={clearFilter}
          parseFilterToArrayOfTagsData={parseFilterToArrayOfTagsData}
          onUpdateFilter={setFilter}
          onSaveAsDefaultFilter={(filters) => {
            setTableDefFilters({
              name: TableTypes.ALL_SERVICES,
              filters,
            });
          }}
          formComponent={AllServicesFilterForm}
        />

        <div className="flex flex-row items-center space-x-2">
          <SimpleSearch onSearchChange={handleSearchFilter} />

          {!hideCreate && checkPermissions([PermissionsTypes.CREATE_SERVICE]) ? (
            <Dialog open={createServiceDialogOpen} onOpenChange={setCreateServiceDialogOpen}>
              <DialogTrigger asChild>
                <Button size="sm">
                  <Plus className="mr-1 h-4 w-4" /> {t('Buttons.addService')}
                </Button>
              </DialogTrigger>
              <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-[1280px]">
                <DialogHeader>
                  <DialogTitle>{t('Pages.AllServices.addNewService')}</DialogTitle>
                  <DialogDescription></DialogDescription>
                </DialogHeader>
                <CreateServiceForm
                  onCancel={() => setCreateServiceDialogOpen(false)}
                  onRefresh={() => {
                    setCreateServiceDialogOpen(false);
                    refetchServices();
                  }}
                />
              </DialogContent>
            </Dialog>
          ) : null}

          {!hideTableSettings && <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />}
        </div>
      </header>

      <div className="mb-2 grid h-10 w-full max-w-[1200px] grid-cols-7 gap-1">
        <ToggleFilter
          label={t('Buttons.availableClients')}
          onChange={() => changeState(STATES_TYPES.CLIENT_AVAILABLE)}
          isActive={stage === STATES_TYPES.CLIENT_AVAILABLE}
        />
        <ToggleFilter
          label={t('Buttons.contactingParent')}
          onChange={() => changeState(STATES_TYPES.CONTACTING_PARENTS)}
          isActive={stage === STATES_TYPES.CONTACTING_PARENTS}
        />
        <ToggleFilter
          label={t('Buttons.pendingApproval')}
          onChange={() => changeState(STATES_TYPES.PENDING_APPROVAL)}
          isActive={stage === STATES_TYPES.PENDING_APPROVAL}
        />
        <ToggleFilter
          label={t('Buttons.therapistAssigned')}
          onChange={() => changeState(STATES_TYPES.THERAPIST_ASSIGNED)}
          isActive={stage === STATES_TYPES.THERAPIST_ASSIGNED}
        />
        <ToggleFilter
          label={t('Buttons.onHold')}
          onChange={() => changeState(STATES_TYPES.ON_HOLD)}
          isActive={stage === STATES_TYPES.ON_HOLD}
        />
        <ToggleFilter
          label={t('Buttons.terminated')}
          onChange={() => changeState(STATES_TYPES.TERMINATED)}
          isActive={stage === STATES_TYPES.TERMINATED}
        />
        {checkPermissions([PermissionsTypes.VIEW_FILTER_ARCHIVED]) && (
          <ToggleFilter label={t('Buttons.archived')} onChange={changeArchivedFilter} isActive={showDeleted} />
        )}
      </div>

      <div className={cn('relative h-[calc(100vh-296px)] max-w-full overflow-auto', tableClassName)}>
        <div className="absolute w-full">
          <CommonTable
            table={table}
            onClickByRow={(rowOriginal) => previewServiceDialogRef.current?.open(rowOriginal)}
          />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          className="mt-1"
          changeCurrentPage={(page) =>
            setPagination((prev) => {
              return {
                ...prev,
                page: page - 1,
              };
            })
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      {isLoading && (
        <div className="flex h-9 w-full items-center justify-center">
          <span className="mt-1 text-sm text-gray-400">{t('Common.loadingWait')}</span>
        </div>
      )}

      <PreviewClientDialog onUpdated={refetchServices} ref={previewClientDialogRef} />
      <PreviewTherapistDialog onUpdated={refetchServices} ref={previewTherapistDialogRef} />
      <PreviewGuardianDialog onUpdated={refetchServices} ref={previewGuardianDialogRef} />

      <ConfirmDialog
        ref={confirmDialogRef}
        onConfirm={(id) => removeService(id)}
        title={t('Pages.AllServices.deleteService')}
        type="delete"
      />

      <PreviewServiceDialog ref={previewServiceDialogRef} />
    </>
  );
}
