import { TagItem } from '@/components/form/tags-selector/tags-selector';

export const sortOrderValueToAPIParams = (
  value?: { id: string; desc: boolean }[]
): { sortField: string | null; sortOrder: 'ASC' | 'DESC' } => {
  if (value && value[0]) {
    let sortField = null;
    switch (value[0].id) {
      case 'Title':
        sortField = 'title';
        break;
      case 'Full Name':
        sortField = 'lastName';
        break;
      case 'Email':
        sortField = 'email';
        break;
      case 'Specialty':
        sortField = 'specialty';
        break;
      case 'Type':
        sortField = 'jobRoleType';
        break;
      case 'Service Roles':
        sortField = 'serviceRole';
        break;
      case 'Regional Center':
        sortField = 'regionalCenter';
        break;
      case 'Start Time':
        sortField = 'startAt';
        break;
      case 'Therapist':
        sortField = 'therapist';
        break;
      case 'Service':
        sortField = 'service';
        break;
      case 'Client':
        sortField = 'client';
        break;
      case 'Date':
        sortField = 'startAt';
        break;
      case 'Due Date':
        sortField = 'expiryDate';
        break;
      case 'Date added':
        sortField = 'createdAt';
        break;
      case 'Hours worked':
        sortField = 'hoursProvided';
        break;
      case 'Initial visit':
        sortField = 'initialVisitDate';
        break;
      default:
        sortField = null;
        break;
    }
    return {
      sortField,
      sortOrder: value[0].desc ? 'DESC' : 'ASC',
    };
  } else {
    return {
      sortField: null,
      sortOrder: 'ASC',
    };
  }
};

export const parseFilterToArrayOfTagsData = <T>(filtersData: T): TagItem[] => {
  let tags: TagItem[] = [];

  for (const key in filtersData) {
    if (Object.hasOwnProperty.call(filtersData, key)) {
      const value = filtersData[key] as string | TagItem[];
      if (Array.isArray(value)) {
        const values = value.map((tag) => {
          tag.value.type = key;
          return tag;
        });
        tags.push(...values);
      } else if (value && typeof value === 'string') {
        tags.push({
          label: value,
          value: { id: value, type: key },
        });
      }
    }
  }
  return tags;
};

export const clearFilter = <T>(filtersData: T, filterValue: TagItem, field: string): T => {
  if (Object.hasOwnProperty.call(filtersData, field)) {
    const value = (filtersData as { [key: string]: string | TagItem[] })[field];
    if (Array.isArray(value)) {
      return {
        ...filtersData,
        [field]: value.filter((tag) => tag.value.id !== filterValue.value.id),
      };
    } else if (typeof value === 'string') {
      return {
        ...filtersData,
        [field]: '',
      };
    }
  }
  return filtersData;
};
