'use client';
import { Button, Popover, PopoverContent, PopoverTrigger } from '@/components/ui';
import { format } from 'date-fns';
import { FormControl, FormMessage } from '../form';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from './calendar';
import { cn } from '@/lib/utils';
import { FieldError } from 'react-hook-form';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
  field: any;
  isError?: FieldError;
  isOptional?: boolean;
  className?: string;
  onSelect?: (date: Dayjs) => void;
  disableDates?: 'prev' | 'next' | 'none';
}
export function DatePicker({ field, isError, disableDates = 'next', className, isOptional, onSelect }: Props) {
  const t = useTranslations();
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            variant={'outline'}
            className={cn(
              'h-10 w-full pl-3 text-left font-normal',
              !field.value && 'text-muted-foreground',
              !!isError && 'border-destructive',
              className
            )}
          >
            {field.value ? dayjs(field.value).format('MM.DD.YYYY') : <span>{t('Common.pickDate')}</span>}
            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent withoutPortal className="w-auto p-0" align="start">
        <>
          <Calendar
            mode="single"
            selected={field.value}
            onSelect={(e) => {
              field.onChange(dayjs(e));
              onSelect?.(dayjs(e));
              setOpen(false);
            }}
            disabled={(date) => {
              const dateToCheck = dayjs(date);
              const currentDate = dayjs();
              return disableDates === 'none'
                ? false
                : disableDates === 'next'
                  ? dateToCheck.isAfter(currentDate)
                  : dateToCheck.isBefore(currentDate.add(-1, 'day'));
            }}
            initialFocus
          />
          {isOptional && (
            <div className="my-2 flex w-full flex-row items-center justify-end px-2">
              <Button
                type="button"
                onClick={() => {
                  field.onChange('');
                  setOpen(false);
                }}
                variant="outlineDestructive"
                size="sm"
              >
                Clear
              </Button>
            </div>
          )}
        </>
      </PopoverContent>
    </Popover>
  );
}

// EXAMPLE USE

// <FormField
// control={form.control}
// name="dateOfBirth"
// render={({ field, fieldState }) => (
//   <FormItem className="flex flex-col">
//     <FormLabel>Date of birth</FormLabel>
//     <DatePicker field={field} fieldState={fieldState} />
//     <FormMessage className="absolute" />
//   </FormItem>
// )}
// />
