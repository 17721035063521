import { Dialog } from '@/components/ui';
import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { DialogDescription } from '@radix-ui/react-dialog';

import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { ConfirmDialog } from '@/components/common';
import { ConfirmDialogRef } from '@/components/common/confirm-dialog';
import { Client } from '../interfaces/clients.interface';
import { ManageClientChildManuallyValidationSchema } from './manage-client-child-manually-validation-schema';
import { ManageClientChildManuallyForm } from './manage-client-child-manually-form';

interface Props {
  onUpdated: () => void;
  onDelete: (id: string) => void;
}

export type ManageClientDialogRef = {
  open: (client: Client) => void;
};

const ManageClientDialog = forwardRef<ManageClientDialogRef, Props>(({ onUpdated, onDelete }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const clientRef = useRef<Client | null>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>();

  useImperativeHandle(ref, () => ({
    open: (client) => {
      setIsOpen(true);
      clientRef.current = client;
    },
  }));

  const handleDeleteClient = (client: ManageClientChildManuallyValidationSchema) => {
    if (clientRef.current && clientRef.current.id) {
      const value = { ...clientRef.current, ...client };
      confirmDialogRef.current?.open({
        description: t('Toasts.confirmDeleteClient', { name: `${value.firstName} ${value.lastName}` }),
        value: value.id,
      });
    }
  };

  const handleUpdateClient = () => {
    setIsOpen(false);
    onUpdated();
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className='w-full max-w-[1280px]'>
          <DialogHeader>
            <DialogTitle>{t('Pages.AllClients.clientProfile')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <div className="pt-4">
            {clientRef.current && (
              <ManageClientChildManuallyForm
                client={clientRef.current}
                onDelete={handleDeleteClient}
                onRefresh={handleUpdateClient}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        ref={confirmDialogRef}
        onConfirm={(id) => {
          onDelete(id);
          setIsOpen(false);
        }}
        title={t('Common.deleteClient')}
        type="delete"
      />
    </>
  );
});

ManageClientDialog.displayName = 'ManageClientDialog';

export { ManageClientDialog };
