export interface Frequency {
  label: string;
  count: number;
  type: 'week' | 'month';
}

export const FREQUENCIES: Frequency[] = [
  { label: '1h/week', count: 1, type: 'week' },
  { label: '2h/week', count: 2, type: 'week' },
  { label: '3h/week', count: 3, type: 'week' },
  { label: '4h/week', count: 4, type: 'week' },
  { label: '5h/week', count: 5, type: 'week' },
  { label: '1h/month', count: 1, type: 'month' },
  { label: '2h/month', count: 2, type: 'month' },
  { label: '6h/month', count: 6, type: 'month' },
  { label: '8h/month', count: 8, type: 'month' },
  { label: '5h/month', count: 5, type: 'month' },
  { label: '10h/month', count: 10, type: 'month' },
];
