import { z } from 'zod';
import dayjs, { Dayjs } from 'dayjs';

export const createDocumentViaTemplateSchema = z
  .object({
    therapist: z.string().min(1, { message: 'Forms.therapist.required' }),
    service: z.string().min(1, { message: 'Forms.service.required' }),
    client: z.string().min(1, { message: 'Forms.client.required' }), 
    // dueDate: z.custom<Dayjs>(
    //   (value) => {
    //     return dayjs.isDayjs(value);
    //   },
    //   { message: 'Forms.dueDate.required' }
    // ),
    dueDate: z.union([
      z.string().length(0), // Allows empty string
      z.custom<Dayjs>(
        (value) => {
          return dayjs.isDayjs(value);
        },
        { message: 'Forms.dueDate.required' }
      )
    ]),
    status: z.string().min(1, { message: 'Forms.status.required' }),
    documentType: z.string().min(1, { message: 'Forms.documentType.required' }),
  });

export type CreateDocumentViaTemplateSchema = z.infer<typeof createDocumentViaTemplateSchema>;
