import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { TableTypes } from '@/common/enums/common';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

interface RequestData {
  name: TableTypes;
  settings: Settings[];
}

interface Settings {
  isVisible: boolean;
  title: string;
}

export type Options = Omit<UseMutationOptions<string, DefaultError, RequestData>, 'mutationKey' | 'mutationFn'>;

export const useTableSettingsMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: RequestData): Promise<string> => {
    const { data } = await axiosInterceptorInstance.post(`table-view-configuration`, value);
    return data;
  };

  return useMutation({
    mutationKey: ['table-view-configuration'],
    mutationFn: _mutation,
    onSettled: ((data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['table-view-configuration'] });
      }
    }),
    ...options,
  });
};
