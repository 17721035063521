import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Service } from '@/views/all-services/interfaces/services.interface';
import * as _ from 'lodash';
import { stringify } from 'qs';

type Response = {
  data: Service[];
  totalCount: number;
};
interface Params {
  skip: number;
  take: number;
  sortField?: string | null;
  sortOrder?: 'ASC' | 'DESC';
  search?: string;
  regionalCenter?: string;
  therapistId?: string;
  showDeleted?: boolean;
  clientId?: string;
  stage?: string;
  initialVisit?: string; // yes/no
  inHomeOnly?: string; // yes/no
  serviceRoleType?: string;
  location?: string; // In-Home/Online
}

export type Options<T> = Omit<UseQueryOptions<unknown, DefaultError, T>, 'queryKey' | 'queryFn'>;

export const useServicesQuery = <T = Response>(params: Params, options?: Options<T>) => {
  const queryParams = {
    skip: params.skip,
    take: params.take,
    sortField: params.sortField,
    sortOrder: params.sortField ? params.sortOrder : null,
    showDeleted: params.showDeleted,
    clientIdFilter: params.clientId,
    search: params.search,
    regionalCenterFilter: params.regionalCenter,
    statusFilter: params.stage,
    therapistIdFilter: params.therapistId,
    inPersonOnly: params.inHomeOnly === 'In-person On' ? true : params.inHomeOnly === 'In-person Off' ? false : '',
    initialVisitDateFilter: params.initialVisit === 'Had initial visit' ? true : params.initialVisit === `Don't have initial visit` ? false : '',
    locationFilter: params.location,
    serviceRoleTypeFilter: params.serviceRoleType,
  };

  const cleanedQueryParams = _.omitBy(queryParams, (value) => _.isNil(value) || value === '');

  const _query = async (): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.get('services', {
      params: cleanedQueryParams,
      paramsSerializer: (params) => {
        return stringify(params, { arrayFormat: 'repeat' });
      },
    });
    return data;
  };

  return useQuery({
    queryKey: ['services', queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
