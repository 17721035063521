'use client';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Textarea,
  Input,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn, generateTimeSlots } from '@/lib/utils';
import { Button } from '@/components/ui';
import { ConfirmDialog, ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import { DialogFooter } from '@/components/ui/dialog';
import { useEffect, useRef, useState } from 'react';
import { DatePicker } from '@/components/form/date-picker/date-picker';
import { RecurringShift, RecurringShiftData } from './recurring-shift-popover';
import { TypesOfShiftRepeat } from '@/common/enums/common';
import dayjs from '@/lib/dayjsConfig';
import { useUpdateShiftMutation } from '@/hooks/fetchers/mutations/shifts/useUpdateShiftMutation';
import { editShiftValidationSchema, EditShiftValidationSchema } from './edit-shift-validation-schema';
import { Shift } from '../interfaces/shift.interface';
import { PermissionsTypes } from '@/lib/RBAC/enums/permissions-types';
import { useIsAllowed } from '@/lib/RBAC';
import { useMask } from '@react-input/mask';
import { TIME_MASK } from '@/common/constants';

const TIME_SLOTS = generateTimeSlots(6);

interface Props {
  onCancel?: () => void;
  onRefresh: () => void;
  shift: Shift;
}

export function EditShiftForm({ onCancel, onRefresh, shift }: Props) {
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();
  const inputClockInRef = useMask({ ...TIME_MASK });
  const inputClockOutRef = useMask({ ...TIME_MASK });

  const [recurringShiftData, setRecurringShiftData] = useState<RecurringShiftData>({
    repeatOnWeek: shift.recurrenceType === TypesOfShiftRepeat.WEEKLY ? shift.weekOrMonthDay : 0,
    configureRepeat: shift.recurrenceType,
    repeatOnDay: shift.recurrenceType === TypesOfShiftRepeat.MONTHLY ? shift.weekOrMonthDay.toString() : '1',
    endsOn: shift.recurrenceType === TypesOfShiftRepeat.SINGLE ? dayjs(shift.startAt) : dayjs(shift.lastRepeatDate),
  });

  const prevRecurringShiftRef = useRef<RecurringShiftData>({
    repeatOnWeek: shift.recurrenceType === TypesOfShiftRepeat.WEEKLY ? shift.weekOrMonthDay : 0,
    configureRepeat: shift.recurrenceType,
    repeatOnDay: shift.recurrenceType === TypesOfShiftRepeat.MONTHLY ? shift.weekOrMonthDay.toString() : '1',
    endsOn: shift.recurrenceType === TypesOfShiftRepeat.SINGLE ? dayjs(shift.startAt) : dayjs(shift.lastRepeatDate),
  });

  const { mutate: update, isPending: isUpdating } = useUpdateShiftMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.shiftCreated'),
      });
      form.reset();
      onRefresh();
    },
  });

  const form = useForm<EditShiftValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(editShiftValidationSchema),
    defaultValues: {
      date: dayjs(shift.startAt),
      startTime: shift.startTime,
      endTime: shift.endTime,
      clockIn: shift.clockIn ? dayjs(shift.clockIn, 'hh:mm A').format('hh:mm A') : '', // For admins
      clockOut: shift.clockOut ? dayjs(shift.clockOut, 'hh:mm A').format('hh:mm A') : '', // For admins
      notes: shift.notes ?? '',
    },
  });

  const endTimeSelector = form.watch('endTime');
  const startTimeSelector = form.watch('startTime');

  useEffect(() => {
    if (endTimeSelector && form.getValues('endTime')) {
      form.trigger('endTime');
    }
    if (startTimeSelector && form.getValues('startTime')) {
      form.trigger('startTime');
    }
  }, [endTimeSelector, startTimeSelector]);

  const onSubmit = (isOnlyOne?: boolean) => {
    if (form.formState.isValid) {
      const value = form.getValues();
      const data = {
        ...value,
        ...recurringShiftData,
        configureRepeat: isOnlyOne ? TypesOfShiftRepeat.SINGLE : recurringShiftData.configureRepeat
      };
      update({ id: shift.id, ...data });
    } else {
      form.trigger();
    }
  };

  return (
    <>
      <Form {...form}>
        <form noValidate>
          <ScrollArea className="h-[calc(100vh-300px)] overflow-auto">
            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              <div className="relative">
                <FormField
                  control={form.control}
                  name="startTime"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel isRequired>{t('Forms.startTime.label')}</FormLabel>
                      <FormControl>
                        <Select value={field.value?.toString()} onValueChange={field.onChange}>
                          <FormControl>
                            <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                              <SelectValue placeholder={t('Forms.startTime.placeholder')} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {TIME_SLOTS.map((item) => (
                              <SelectItem key={item} value={item}>
                                {item}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>

              <div className="relative">
                <FormField
                  control={form.control}
                  name="endTime"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel isRequired>{t('Forms.endTime.label')}</FormLabel>
                      <FormControl>
                        <Select value={field.value?.toString()} onValueChange={field.onChange}>
                          <FormControl>
                            <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                              <SelectValue placeholder={t('Forms.endTime.placeholder')} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {TIME_SLOTS.map((item) => (
                              <SelectItem key={item} value={item}>
                                {item}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>
              {checkPermissions([PermissionsTypes.EDIT_SHIFTS_CLOCK_IN_CLOCK_OUT]) ? (
                <>
                  <div className="relative">
                    <FormField
                      control={form.control}
                      name="clockIn"
                      render={({ field, fieldState }) => (
                        <FormItem>
                          <FormLabel>{t('Forms.clockInTime.label')}</FormLabel>
                          <FormControl>
                            <Input
                              hasError={!!fieldState.error}
                              className="w-full"
                              {...field}
                              ref={inputClockInRef}
                              type="text"
                              placeholder="--:-- AM/PM"
                            />
                          </FormControl>
                          <FormMessage className="absolute" />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="relative">
                    <FormField
                      control={form.control}
                      name="clockOut"
                      render={({ field, fieldState }) => (
                        <FormItem>
                          <FormLabel>{t('Forms.clockOutTime.label')}</FormLabel>
                          <FormControl>
                            <Input
                              hasError={!!fieldState.error}
                              className="w-full"
                              {...field}
                              ref={inputClockOutRef}
                              type="text"
                              placeholder="--:-- AM/PM"
                            />
                          </FormControl>
                          <FormMessage className="absolute" />
                        </FormItem>
                      )}
                    />
                  </div>
                </>
              ) : null}

              <div className="relative">
                <FormField
                  control={form.control}
                  name="date"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel isRequired>{t('Forms.date.label')}</FormLabel>
                      <DatePicker disableDates="prev" field={field} isError={fieldState.error} />
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="ml-1 mt-6 flex w-full max-w-[676px] flex-col space-y-6">
              <div className="relative mt-6">
                <FormField
                  control={form.control}
                  name="notes"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel>{t('Forms.notes.label')}</FormLabel>
                      <FormControl>
                        <Textarea placeholder={t('Forms.notes.placeholder')} className="resize-none" {...field} />
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </ScrollArea>
        </form>
      </Form>

      <DialogFooter className="mt-10 flex h-20 w-full flex-row items-center justify-between border-t border-t-gray-300 pt-4 sm:justify-between">
        <RecurringShift value={recurringShiftData} onChange={(e) => setRecurringShiftData(e)} />
        <div className="space-x-2">
          <Button onClick={onCancel} variant="ghost" type="button" size="lg">
            {t('Buttons.cancel')}
          </Button>

          {recurringShiftData.configureRepeat !== TypesOfShiftRepeat.SINGLE && (JSON.stringify(prevRecurringShiftRef.current) !== JSON.stringify(recurringShiftData)) ? (
            <ConfirmDialog
              onConfirm={() => onSubmit(true)}
              onCancel={() => onSubmit()}
              title={t('Buttons.save')}
              okBtnName={t('Buttons.onlyOne')}
              noBtnName={t('Buttons.all')}
              type="warning"
              description={t('Common.confirmEditShift')}
            >
              <Button type="button" size="lg" disabled={isUpdating}>
                {t('Buttons.save')}
              </Button>
            </ConfirmDialog>
          ) : (
            <Button type="button" onClick={() => onSubmit(true)} size="lg" disabled={isUpdating}>
              {t('Buttons.save')}
            </Button>
          )}
        </div>
      </DialogFooter>
    </>
  );
}
