import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as _ from 'lodash';

export type Options<T> = Omit<UseQueryOptions<unknown, DefaultError, T>, 'queryKey' | 'queryFn'>;
interface Params {
  search?: string;
}

export const useSupervisorsQuery = <T = Response>(params: Params, options?: Options<T>) => {
  const queryParams = {
    search: params.search,
  };
  const cleanedQueryParams = _.omitBy(queryParams, value => _.isNil(value) || value === '');

  const _query = async (): Promise<T> => {
    const { data } = await axiosInterceptorInstance.get('therapists/supervisors', {
      params: cleanedQueryParams
    });
    return data;
  };

  return useQuery({
    queryKey: ['therapists', 'supervisors', queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
