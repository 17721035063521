'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@/components/ui';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { REGIONAL_CENTERS, SERVICE_ROLE_TYPES, SERVICE_ROLES, STATES } from '@/common/constants';

export interface FilterAllServicesParams {
  search?: string;
  regionalCenter?: string;
  // stage?: string;
  inHomeOnly?: string; // yes/no
  initialVisit?: string; // yes/no
  serviceRoleType?: string;
  location?: string;
}

interface Props {
  value?: FilterAllServicesParams;
  onApply: (value: FilterAllServicesParams) => void;
  onSaveAsDefault: (value: FilterAllServicesParams) => void;
  onCancel: () => void;
}


export function AllServicesFilterForm({ value, onApply, onSaveAsDefault, onCancel }: Props) {
  const t = useTranslations();

  const form = useForm<FilterAllServicesParams>({
    defaultValues: {
      regionalCenter: value ? value.regionalCenter : '',
      // stage: value ? value.stage : '',
      inHomeOnly: value ? value.inHomeOnly : '',
      initialVisit: value ? value.initialVisit : '',
      serviceRoleType: value ? value.serviceRoleType : '',
      location: value ? value.location : '',
    },
  });

  const onSubmit: SubmitHandler<FilterAllServicesParams> = (data: FilterAllServicesParams): void => {
    onApply(data);
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <div className="pl-1 pr-4">
          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="regionalCenter"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.regionalCenter.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.regionalCenter.placeholder')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {REGIONAL_CENTERS.map((rc) => (
                          <SelectItem key={rc} value={rc}>
                            {rc}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>
          {/* <div className="relative mb-2">
            <FormField
              control={form.control}
              name="stage"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.stage.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.stage.placeholder')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {STATES.map((stage) => (
                          <SelectItem key={stage} value={stage}>
                            {stage}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div> */}

          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="location"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.setting.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.setting.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {SERVICE_ROLES.map((rc) => (
                          <SelectItem key={rc} value={rc}>
                            {rc}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="inHomeOnly"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.inPersonOnly.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.inPersonOnly.placeholder')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        <SelectItem value="In-person On">In-person On</SelectItem>
                        <SelectItem value="In-person Off">In-person Off</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="initialVisit"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.initialVisit.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.initialVisit.placeholder')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        <SelectItem value="Had initial visit">Had initial visit</SelectItem>
                        <SelectItem value="Don't have initial visit">Don't have initial visit</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="serviceRoleType"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.serviceRoleType.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.serviceRoleType.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {SERVICE_ROLE_TYPES.map((rc) => (
                          <SelectItem key={rc} value={rc}>
                            {rc}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>
        </div>

        <footer className="flex flex-row items-center justify-end space-x-2">
          <Button onClick={onCancel} variant="outline" type="button" size="sm" className="mt-8">
            {t('Buttons.cancel')}
          </Button>
          {/* <Button
            onClick={() => {
              onSaveAsDefault(form.getValues());
            }}
            type="button"
            variant="outline"
            size="sm"
            className="mt-8"
          >
            {t('Buttons.save')}
          </Button> */}
          <Button type="submit" size="sm" className="mt-8">
            {t('Buttons.apply')}
          </Button>
        </footer>
      </form>
    </Form>
  );
}
