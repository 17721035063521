import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { TableTypes } from '@/common/enums/common';

export interface Response {
  id: string;
  name: TableTypes;
  settings: Settings[];
}

interface Settings {
  isVisible: boolean;
  title: string;
}

export type Options = Omit<UseQueryOptions<unknown, DefaultError, Response>, 'queryKey' | 'queryFn'>;

export const useTableSettingsQuery = (table: TableTypes, options?: Options) => {
  const _query = async (): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.get(`table-view-configuration`, {
      params: { name: table },
    });
    return data;
  };

  return useQuery({
    queryKey: ['table-view-configuration', table],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
