import { CreateManualDocumentValidationSchema } from '@/views/all-documents/manage/create-manual-document-validation-schema';
import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import * as _ from 'lodash';
import { DOCUMENT_STATUS_TYPES } from '@/common/constants/common';
import { Dayjs } from 'dayjs';

export interface Request extends CreateManualDocumentValidationSchema {
  file: File;
  progressCallBack?: (progressEvent: ProgressEvent) => void;
}

export type Options = Omit<UseMutationOptions<AxiosResponse, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useCreateManualDocumentMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: Request): Promise<AxiosResponse<any>> => {
    const { file, documentType, status, dueDate } = value;
    const parsedValue = {
      file,
      documentType,
      status: status === DOCUMENT_STATUS_TYPES.Expired ? DOCUMENT_STATUS_TYPES['Past due'] : status, // @TODO: Change Past due to Expired on BE
      dueDate: dueDate !== '' ? (dueDate as Dayjs).format('YYYY-MM-DD'): '',
      therapistId: value.therapist ? JSON.parse(value.therapist).id : null,
      serviceId: value.service ? JSON.parse(value.service).id : null,
      clientId: value.client ? JSON.parse(value.client).id : null,
    };

    const formDataValues = _.omitBy(parsedValue, (v) => _.isNil(v) || v === '');
    let fd = new FormData();

    const formDataEntries = Object.entries(formDataValues);
    for (const [prop, value] of formDataEntries) {
      fd.append(prop, value);
    }

    const axiosResponse = await axiosInterceptorInstance.post(`documents`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
      },
      onUploadProgress: (progressEvent: any) => {
        value.progressCallBack && value.progressCallBack(progressEvent);
      },
    });
    return axiosResponse;
  };

  return useMutation({
    mutationKey: ['documents'],
    onSettled: ((data, error) => {
      if (!error) { 
        queryClient.invalidateQueries({ queryKey: ['documents'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    }),
    mutationFn: _mutation,
    ...options,
  });
};
