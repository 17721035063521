import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ManageClientChildManuallyValidationSchema } from '@/views/all-clients/manage/manage-client-child-manually-validation-schema';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash';

export type Options = Omit<
  UseMutationOptions<string, DefaultError, ManageClientChildManuallyValidationSchema>,
  'mutationKey' | 'mutationFn'
>;

export const useCreateClientChildManuallyMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: ManageClientChildManuallyValidationSchema): Promise<string> => {
    const body = _.omitBy(value, (v) => _.isNil(v) || v === '');
    const { data } = await axiosInterceptorInstance.post(`clients`, body);
    return data;
  };

  return useMutation({
    mutationKey: ['clients'],
    mutationFn: _mutation,
    onSettled: (data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['clients'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    },
    ...options,
  });
};
