'use client';

import { Colors } from '@/common/enums';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../collapsible';
import { Chips } from '../chips';
import { Button } from '@/components/ui';

interface Props {
  visible: { id: string; title: string; color: Colors }[];
  hidden: { id: string; title: string; color: Colors }[];
}

export function CollapsibleColumn({ visible = [], hidden = [] }: Props) {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-row flex-wrap gap-1 py-2">
      <Collapsible open={isOpen} onOpenChange={setIsOpen} className="w-[250px] space-y-2">
        {visible && (
          <>
            {visible.map((item) => (
              <Chips color={item.color} key={item.id} title={item.title} />
            ))}
          </>
        )}
        <CollapsibleContent className="space-y-2">
          {hidden && (
            <>
              {hidden.map((item) => (
                <Chips color={item.color} key={item.id} title={item.title} />
              ))}
            </>
          )}
        </CollapsibleContent>
        {hidden.length > 0 && (
          <CollapsibleTrigger asChild>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen((prev) => !prev);
              }}
            >
              {isOpen ? (<Chips color={Colors.gray} title='Hide' />) : <Chips color={Colors.gray} title={`+${hidden.length}`} /> }
              {/* {isOpen ? (
                <Button
                  className="ml-[-8px]"
                  variant="link"
                  size="sm"
                >{`Hide`}</Button>
              ) : (
                <Button
                  className="ml-[-8px]"
                  variant="link"
                  size="sm"
                >{`Show ${hidden.length} hidden`}</Button>
              )} */}
            </div>
          </CollapsibleTrigger>
        )}
      </Collapsible>
    </div>
  );
}
