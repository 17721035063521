import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

type Request = {
  id: string;
  note: string;
};

export type Options = Omit<UseMutationOptions<Response, DefaultError, any>, 'mutationKey' | 'mutationFn'>;

export const useDeclineServiceMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async ({ id, note }: Request): Promise<any> => {
    const { data } = await axiosInterceptorInstance.patch(`services/${id}/decline`, { note });
    return data;
  };

  return useMutation({
    mutationKey: ['services', 'decline'],
    onSettled: ((data, error) => {
      if (!error) {
        queryClient.resetQueries({ queryKey: ['services'] });
        queryClient.invalidateQueries({ queryKey: ['clients'] });
        queryClient.invalidateQueries({ queryKey: ['therapists'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    }),
    mutationFn: _mutation,
    ...options,
  });
};
