'use client';

import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Textarea,
  Input,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn, generateTimeSlots } from '@/lib/utils';
import { Button } from '@/components/ui';
import { ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import { DialogFooter } from '@/components/ui/dialog';
import { SERVICE_ROLE_TYPES, SERVICE_ROLES } from '@/common/constants';
import { useClientsQuery } from '@/hooks/fetchers/queries/clients/useClientsQuery';
import { TagItem } from '@/components/form/tags-selector/tags-selector';
import { Client } from '@/views/all-clients/interfaces/clients.interface';
import { useServiceRolesQuery } from '@/hooks/fetchers/queries/service-roles/useServiceRolesQuery';
import { useEffect, useState } from 'react';
import { ServiceRole } from '@/views/service-roles/interfaces/service-role.interface';
import * as _ from 'lodash';
import { useTherapistsQuery } from '@/hooks/fetchers/queries/therapists/useTherapistsQuery';
import { Therapist } from '@/views/all-therapists/interfaces/therapists.interface';
import { addShiftValidationSchema, AddShiftValidationSchema } from './add-shift-validation-schema';
import { useServicesQuery } from '@/hooks/fetchers/queries/services/useServicesQuery';
import { DatePicker } from '@/components/form/date-picker/date-picker';
import { RecurringShift, RecurringShiftData } from './recurring-shift-popover';
import { Service } from '@/views/all-services/interfaces/services.interface';
import { useCreateShiftMutation } from '@/hooks/fetchers/mutations/shifts/useCreateShiftMutation';
import { TypesOfShiftRepeat } from '@/common/enums/common';
import dayjs from '@/lib/dayjsConfig';
import { MyClient } from '@/views/my-clients/interfaces/my-clients.interface';

const TIME_SLOTS = generateTimeSlots(6);

interface Props {
  onCancel?: () => void;
  onRefresh: () => void;
  service?: MyClient;
}

export function AddShiftForAdminsForm({ onCancel, onRefresh, service }: Props) {
  const t = useTranslations();
  const [therapistId, setTherapistId] = useState();
  const [keywordClient, setKeywordClient] = useState('');
  const [keywordService, setKeywordService] = useState('');
  const [keywordServiceRole, setKeywordServiceRole] = useState('');
  const [keywordTherapist, setKeywordTherapist] = useState('');
  const [recurringShiftData, setRecurringShiftData] = useState<RecurringShiftData>({
    repeatOnWeek: 0,
    configureRepeat: TypesOfShiftRepeat.SINGLE,
    repeatOnDay: '1',
    endsOn: dayjs(),
  });

  const { data: clients, isLoading: isLoadingClients } = useClientsQuery<TagItem[]>(
    {
      search: keywordClient,
      skip: 0,
      take: 200,
    },
    {
      enabled: false,
      select: (value: any): TagItem[] =>
        value.data.map((client: Client) => ({
          label: `${client.firstName} ${client.lastName}`,
          value: {
            id: client.id,
            firstName: client.firstName,
            lastName: client.lastName,
          },
        })),
    }
  );
  const { data: services, isLoading: isLoadingServices } = useServicesQuery<TagItem[]>(
    {
      search: keywordService,
      therapistId: therapistId,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((sr: Service) => ({
          label: sr.title,
          value: {
            id: sr.id,
            title: sr.title,
            serviceRole: sr.serviceRole,
            client: sr.client,
            authorizedTo: sr.authorizedTo,
            therapist: sr.therapist,
          },
        })),
    }
  );
  const { data: serviceRoles, isLoading: isLoadingServiceRoles } = useServiceRolesQuery<TagItem[]>(
    {
      search: keywordServiceRole,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((serviceRole: ServiceRole) => ({
          label: serviceRole.title,
          value: {
            id: serviceRole.id,
            title: serviceRole.title,
            jobRoleTypeName: serviceRole.jobRoleType.name,
            locationName: serviceRole.location.name,
          },
        })),
    }
  );
  const { data: therapists, isLoading: isLoadingTherapists } = useTherapistsQuery<TagItem[]>(
    {
      search: keywordTherapist,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((therapist: Therapist) => ({
          label: `${therapist.firstName} ${therapist.lastName}`,
          value: {
            deactivatedAt: therapist.deactivatedAt,
            firstName: therapist.firstName,
            id: therapist.id,
            lastName: therapist.lastName,
          },
        })),
    }
  );

  const onSearchByClients = (value: string) => {
    setKeywordClient(value);
  };

  const onSearchByServices = (value: string) => {
    setKeywordService(value);
  };

  const onSearchByServiceRoles = (value: string) => {
    setKeywordServiceRole(value);
  };

  const onSearchByTherapists = (value: string) => {
    setKeywordTherapist(value);
  };

  const { mutate: create, isPending: isCreating } = useCreateShiftMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.shiftCreated'),
      });
      form.reset();
      onRefresh();
    },
  });

  const form = useForm<AddShiftValidationSchema>({
    mode: 'onChange',
    resolver: zodResolver(addShiftValidationSchema),
    defaultValues: {
      therapist: '',
      client: '',
      service: service
        ? JSON.stringify({
            id: service.id,
            title: service.title,
            serviceRole: service.serviceRole,
            client: service.client,
            authorizedTo: service.authorizedTo,
            typeOfShift: service.typeOfShift,
            typeOfVisit: service.typeOfVisit,
          })
        : '',
      serviceRole: '',
      date: dayjs(),
      startTime: '',
      endTime: '',
      typeOfShift: service ? service.typeOfShift : '',
      typeOfVisit: service ? (service.typeOfVisit === 'Online' ? 'Online' : 'In-Home') : '',
      notes: '',
    },
  });

  const therapistSelector = form.watch('therapist');
  const serviceSelector = form.watch('service');
  const endTimeSelector = form.watch('endTime');
  const startTimeSelector = form.watch('startTime');

  useEffect(() => {
    if (endTimeSelector && form.getValues('endTime')) {
      form.trigger('endTime');
    }
    if (startTimeSelector && form.getValues('startTime')) {
      form.trigger('startTime');
    }
  }, [endTimeSelector, startTimeSelector]);

  useEffect(() => {
    const { therapist, service } = form.getValues();
    if (therapist) {
      if (service) {
        const {therapist: therapistFromService} = JSON.parse(service);
        if (therapistFromService && therapistFromService.id !== JSON.parse(therapist).id) {
          form.setValue('service', '');
        }
      }
      setTherapistId(JSON.parse(therapist).id);
    }
  }, [therapistSelector]);

  useEffect(() => {
    const { service, therapist } = form.getValues();
    if (service) {
      const parsedService: Service = JSON.parse(service);
      const { id, firstName, lastName } = parsedService.client;
      form.setValue('client', JSON.stringify({ id, firstName, lastName }));
      form.setValue(
        'serviceRole',
        JSON.stringify({
          id: parsedService.serviceRole.id,
          title: parsedService.serviceRole.title,
          jobRoleTypeName: parsedService.serviceRole.jobRoleType.name,
          locationName: parsedService.serviceRole.location.name,
        })
      );

      if (parsedService.therapist && therapist === '') {
        form.setValue(
          'therapist',
          JSON.stringify({
            deactivatedAt: parsedService.therapist.deactivatedAt,
            firstName: parsedService.therapist.firstName,
            id: parsedService.therapist.id,
            lastName: parsedService.therapist.lastName,
          })
        );
      }

      form.setValue('typeOfShift', parsedService.serviceRole.jobRoleType.name);
      form.setValue('typeOfVisit', parsedService.serviceRole.location.name === 'Online' ? 'Online' : 'In-Home');
      form.clearErrors('typeOfShift');
      form.clearErrors('typeOfVisit');
      form.clearErrors('serviceRole');
      form.clearErrors('client');
      setRecurringShiftData((prev) => ({
        ...prev,
        endsOn: dayjs(parsedService.authorizedTo),
      }));
    }
  }, [serviceSelector]);

  const onSubmit = () => {
    if (form.formState.isValid) {
      const value = form.getValues();
      const data = {
        ...value,
        ...recurringShiftData,
      };
      const isPastDate = dayjs(value.startTime, 'hh:mm A')
      .set('date', value.date.date())
      .set('month', value.date.month())
      .set('year', value.date.year())
      .isBefore(dayjs());

      if ((recurringShiftData.configureRepeat === TypesOfShiftRepeat.SINGLE && isPastDate) || !isPastDate) {
        create(data);
      } else {
        toast({
          title: t('Toasts.warning'),
          typeIcon: ToastTypeEnums.WARNING,
          description: t('Toasts.errorCreateShiftInPast'),
        });
      } 
    } else {
      form.trigger();
    }
  };

  return (
    <>
      <Form {...form}>
        <ScrollArea className="h-[calc(100vh-300px)] overflow-auto">
          <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
            <div className="relative">
              <FormField
                control={form.control}
                name="therapist"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.therapist.label')}</FormLabel>
                    <Select
                      onOpenChange={() => setKeywordTherapist('')}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue
                            placeholder={
                              isLoadingTherapists ? t('Common.loadingWait') : t('Forms.therapist.placeholder')
                            }
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent search onChangeSearch={onSearchByTherapists}>
                        <SelectItem value={null as any}>None</SelectItem>
                        {field.value && field.value !== '' ? (
                          <SelectItem
                            value={field.value}
                          >{`${JSON.parse(field.value).firstName} ${JSON.parse(field.value).lastName}`}</SelectItem>
                        ) : null}

                        {therapists
                          ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                          .map((item) => (
                            <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                              {item.label}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="service"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.service.label')}</FormLabel>
                    <Select
                      onOpenChange={() => setKeywordService('')}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue
                            placeholder={isLoadingServices ? t('Common.loadingWait') : t('Forms.service.placeholder')}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent search onChangeSearch={onSearchByServices}>
                        {field.value && <SelectItem value={field.value}>{JSON.parse(field.value).title}</SelectItem>}

                        {services
                          ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                          .map((item) => (
                            <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                              {item.label}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="client"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.client.label')}</FormLabel>
                    <Select
                      onOpenChange={() => setKeywordClient('')}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue
                            placeholder={isLoadingClients ? t('Common.loadingWait') : t('Forms.client.placeholder')}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent search onChangeSearch={onSearchByClients}>
                        {field.value && (
                          <SelectItem
                            value={field.value}
                          >{`${JSON.parse(field.value).firstName} ${JSON.parse(field.value).lastName}`}</SelectItem>
                        )}

                        {clients
                          ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                          .map((item) => (
                            <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                              {item.label}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="serviceRole"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.serviceRole.label')}</FormLabel>
                    <Select
                      onOpenChange={() => setKeywordTherapist('')}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue
                            placeholder={
                              isLoadingServiceRoles
                                ? t('Common.loadingWait')
                                : t('Forms.serviceRole.placeholderSelectorOne')
                            }
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent search onChangeSearch={onSearchByServiceRoles}>
                        <SelectItem value={null as any}>None</SelectItem>
                        {field.value && field.value !== '' ? (
                          <SelectItem value={field.value}>{JSON.parse(field.value).title}</SelectItem>
                        ) : null}

                        {serviceRoles
                          ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                          .map((item) => (
                            <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                              {item.label}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="startTime"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.startTime.label')}</FormLabel>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.startTime.placeholder')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {TIME_SLOTS.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="endTime"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.endTime.label')}</FormLabel>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.endTime.placeholder')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {TIME_SLOTS.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="date"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.date.label')}</FormLabel>
                    <DatePicker disableDates="none" field={field} isError={fieldState.error} />
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="typeOfVisit"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.typeOfVisit.label')}</FormLabel>
                    <FormControl>
                      <Select disabled value={field.value?.toString()} onValueChange={field.onChange}>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.typeOfVisit.placeholder')} />
                        </SelectTrigger>
                        <SelectContent>
                          {SERVICE_ROLES.map((item) => (
                            <SelectItem key={item} value={item}>
                              {item}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="typeOfShift"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.typeOfShift.label')}</FormLabel>
                    <FormControl>
                      <Select disabled value={field.value?.toString()} onValueChange={field.onChange}>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.typeOfShift.placeholder')} />
                        </SelectTrigger>
                        <SelectContent>
                          {SERVICE_ROLE_TYPES.map((item) => (
                            <SelectItem key={item} value={item}>
                              {item}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="ml-1 mt-6 flex w-full max-w-[676px] flex-col space-y-6">
            <div className="relative mt-6">
              <FormField
                control={form.control}
                name="notes"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>{t('Forms.notes.label')}</FormLabel>
                    <FormControl>
                      <Textarea placeholder={t('Forms.notes.placeholder')} className="resize-none" {...field} />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </ScrollArea>
      </Form>

      <DialogFooter className="mt-10 flex h-20 w-full flex-row items-center justify-between border-t border-t-gray-300 pt-4 sm:justify-between">
        <RecurringShift value={recurringShiftData} onChange={(e) => setRecurringShiftData(e)} />
        <div className="space-x-2">
          <Button onClick={onCancel} variant="ghost" type="button" size="lg">
            {t('Buttons.cancel')}
          </Button>

          <Button type="button" onClick={onSubmit} size="lg" disabled={isCreating}>
            {t('Buttons.save')}
          </Button>
        </div>
      </DialogFooter>
    </>
  );
}
