import { z } from 'zod';
import dayjs, { Dayjs } from 'dayjs';

export const addShiftValidationSchema = z
  .object({
    therapist: z.string().min(1, { message: 'Forms.therapist.required' }), // For Admins
    service: z.string().min(1, { message: 'Forms.service.required' }), // If a therapist is selected, only related services should be available for selection.
    client: z.string().min(1, { message: 'Forms.client.required' }), // Auto select from service
    serviceRole: z.string().min(1, { message: 'Forms.serviceRole.required' }), // Auto select from service
    date: z.custom<Dayjs>(
      (value) => {
        return dayjs.isDayjs(value);
      },
      { message: 'Forms.endsOn.required' }
    ),
    startTime: z.string().min(1, { message: 'Forms.startTime.required' }),
    endTime: z.string().min(1, { message: 'Forms.endTime.required' }),
    typeOfVisit: z.string().min(1, { message: 'Forms.typeOfVisit.required' }), // auto-populated based on service role location
    typeOfShift: z.string().min(1, { message: 'Forms.typeOfVisit.required' }), // auto-populated based on service role type
    notes: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.startTime && data.endTime) {
      // Define the start and end times
      const startTime = dayjs(data.startTime, 'h:mm A');
      const endTime = dayjs(data.endTime, 'h:mm A');

      // Calculate the duration in minutes
      const timeDifference = endTime.diff(startTime, 'minute');

      // Create a duration of 1 hour 30 minutes (90 minutes)
      const maxDuration = dayjs.duration(90, 'minutes');

      if (timeDifference < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['endTime'],
          message: 'Forms.endTime.invalid',
        });
      }

      // Check if the duration is less than 1 hour 30 minutes
      if (timeDifference > maxDuration.asMinutes()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['endTime'],
          message: 'Forms.endTime.max',
        });
      }

     
    }
  });

export type AddShiftValidationSchema = z.infer<typeof addShiftValidationSchema>;
