'use client';
import { Button } from '@/components/ui';
import { DialogFooter } from '@/components/ui/dialog';
import { cn, compileFullName, compileLastName, getColorForServiceRole } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import { Therapist } from '../interfaces/therapists.interface';
import { toMaskByString } from '@/components/form/phone-input/utils';
import { Chips, LabelValueItem, ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import { StatisticBadge } from '@/components/common/statistic-badge';
import Calendar from './../../../../public/icons/calendar.svg';
import { PermissionsTypes } from '@/lib/RBAC/enums/permissions-types';
import { useIsAllowed } from '@/lib/RBAC';
import { UserTypes, useUserResendLinkMutation } from '@/hooks/fetchers/mutations/useUserResendLinkMutation';
import { PreviewTherapistDialog, PreviewTherapistDialogRef } from './preview-therapist.dialog';
import { createRef } from 'react';
import { USER_ROLES_COLORS } from '@/common/constants/chips-colors';
import { RoleTypes } from '@/lib/RBAC/enums/role-types';

interface Props {
  className?: string;
  therapist?: Therapist;
  onEdit: () => void;
  onRefresh?: () => void;
}

export function TherapistProfileTab({ therapist, className, onEdit, onRefresh }: Props) {
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();

  const previewTherapistDialogRef = createRef<PreviewTherapistDialogRef>();

  const { mutate: resendLink, isPending } = useUserResendLinkMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
      });
      onRefresh?.();
    },
  });

  const previewTherapist = (id: string) => {
    previewTherapistDialogRef.current?.openById(id);
  };

  return (
    <div>
      <div className="relative max-w-[1100px]">
        <div className="absolute right-0 top-0 flex flex-row items-center space-x-2">
          <StatisticBadge
            svgPath={Calendar}
            label={t('Pages.Dashboard.workedVsHoursPlanned')}
            value={`${therapist?.hoursProvided ?? 0}/${therapist?.hoursPlanned ?? 0}`}
          />
          {/* <StatisticBadge svgPath={Folder} label={t('Pages.AllTherapists.reportsDue')} value={0} /> */}
        </div>
        <ScrollArea className={cn('h-[calc(100vh-364px)] overflow-auto', className)}>
          <div className="space-y-4">
            <h2 className="mb-2 mt-4 text-sm font-bold text-gray-950">{t('Common.personalInformation')}</h2>

            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              <LabelValueItem label={t('Forms.firstName.label')} value={therapist?.firstName} />
              <LabelValueItem label={t('Forms.lastName.label')} value={compileLastName(therapist)} />

              {therapist?.supervisor && (
                <LabelValueItem label={t('Forms.supervisor.label')} withoutBorder>
                  <Chips
                    color={USER_ROLES_COLORS[RoleTypes.Therapist]}
                    title={compileFullName(therapist.supervisor)}
                    onClickAction={
                      checkPermissions([PermissionsTypes.PREVIEW_THERAPIST_PROFILE])
                        ? () => previewTherapist(therapist.supervisor?.id as string)
                        : undefined
                    }
                  />
                </LabelValueItem>
              )}
              <LabelValueItem label={t('Forms.accessLevel.label')} value={therapist?.role} />
            </div>

            <div className="mb-6 flex w-full max-w-[676px] flex-col space-y-6">
              <LabelValueItem
                label={t('Forms.speakingLanguage.label')}
                value={
                  therapist && therapist.languages && therapist.languages.length > 0
                    ? therapist.languages.join(', ')
                    : undefined
                }
              />

              <LabelValueItem label={t('Forms.serviceRole.label')}>
                <div className="flex flex-wrap">
                  {therapist &&
                    therapist.serviceRole &&
                    therapist.serviceRole.map((role) => (
                      <Chips
                        className="mr-1"
                        key={role.id}
                        title={role.title}
                        color={getColorForServiceRole(role.title, role.speciality?.code)}
                      />
                    ))}
                </div>
              </LabelValueItem>
            </div>

            <h2 className="my-6 text-sm font-bold text-gray-950">{t('Common.contactInformation')}</h2>

            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              <LabelValueItem label={t('Forms.email.label')} forceLineBreak value={therapist?.email} />
              <LabelValueItem
                label={t('Forms.phoneNumber.label')}
                value={therapist && toMaskByString(therapist.phone)}
              />
              {checkPermissions([PermissionsTypes.VIEW_ADP_ID]) && (
                <LabelValueItem label={t('Forms.adpId.label')} value={therapist?.adpId} />
              )}
            </div>
          </div>
        </ScrollArea>
      </div>

      <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
        {!therapist?.deactivatedAt && (
          <>
            {checkPermissions([PermissionsTypes.RESEND_INVITATION_LINK]) && !therapist?.isAuthorizationFinished ? (
              <Button
                disabled={isPending}
                type="button"
                size="lg"
                variant="outline"
                className="mt-5"
                onClick={() => resendLink({ id: therapist?.id as string, type: UserTypes.THERAPIST })}
              >
                {t('Buttons.resendLink')}
              </Button>
            ) : null}
            {checkPermissions([PermissionsTypes.MANAGE_THERAPIST_DETAILS]) ? (
              <Button type="button" size="lg" className="mt-5" onClick={onEdit}>
                {t('Buttons.edit')}
              </Button>
            ) : null}
          </>
        )}
      </DialogFooter>
      <PreviewTherapistDialog onUpdated={onRefresh} ref={previewTherapistDialogRef} />
    </div>
  );
}
