import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import Image from 'next/image';

import { useTranslations } from 'next-intl';
import { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useShiftQuery } from '@/hooks/fetchers/queries/shifts/useShiftQuery';
import { useIsAllowed } from '@/lib/RBAC';
import { PermissionsTypes } from '@/lib/RBAC/enums/permissions-types';
import { compileFullName, getColorForServiceRole, transformDateToViewDate } from '@/lib/utils';
import {
  Chips,
  ConfirmDialog,
  LabelValueItem,
  ScrollArea,
  toast,
  ToastAction,
  ToastTypeEnums,
} from '@/components/common';
import { Colors } from '@/common/enums';
import { PreviewClientDialog, PreviewClientDialogRef } from '@/views/all-clients/preview/preview-client.dialog';
import {
  PreviewTherapistDialog,
  PreviewTherapistDialogRef,
} from '@/views/all-therapists/preview/preview-therapist.dialog';
import { useCancelShiftMutation } from '@/hooks/fetchers/mutations/shifts/useCancelShiftMutation';
import { Shift } from '../interfaces/shift.interface';
import { ManageShiftDialog, ManageShiftDialogRef } from '../manage/manage-shift.dialog';
import { useRemoveShiftMutation } from '@/hooks/fetchers/mutations/shifts/useRemoveShiftMutation';
import { useRemoveUndoShiftMutation } from '@/hooks/fetchers/mutations/shifts/useRemoveUndoShiftMutation';
import { SERVICE_TYPES, SHIFT_STATUS_TYPES } from '@/common/constants/common';
import { TypesOfShiftRepeat } from '@/common/enums/common';
import { useCancelAllShiftMutation } from '@/hooks/fetchers/mutations/shifts/useCancelAllShiftMutation';
import { FileText } from 'lucide-react';
import { PreviewServiceDialog, PreviewServiceDialogRef } from '@/views/all-services/preview/preview-service.dialog';
import Link from 'next/link';
import { SHIFT_STATUS_COLORS, USER_ROLES_COLORS } from '@/common/constants/chips-colors';
import { RoleTypes } from '@/lib/RBAC/enums/role-types';
import { useResendLinkForSignMutation } from '@/hooks/fetchers/mutations/shifts/useResendLinkForSignMutation';

interface Props {
  onRemove?: (id: string) => void;
  onUpdated?: () => void;
}

export type PreviewShiftDialogRef = {
  openById: (id: string) => void;
};

const PreviewShiftDialog = forwardRef<PreviewShiftDialogRef, Props>(({ onUpdated, onRemove }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [shiftId, setShiftId] = useState<string>();
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();
  const previewTherapistDialogRef = createRef<PreviewTherapistDialogRef>();
  const previewClientDialogRef = createRef<PreviewClientDialogRef>();
  const previewServiceDialogRef = createRef<PreviewServiceDialogRef>();
  const manageShiftDialogRef = createRef<ManageShiftDialogRef>();

  useEffect(() => {
    if (!isOpen && shiftId) {
      setShiftId(undefined);
    }
  }, [isOpen]);

  const { data: shiftDetails, refetch: refetchShift } = useShiftQuery(shiftId, {
    enabled: true,
    refetchOnWindowFocus: isOpen,
  });

  const { mutate: cancelShift, isPending: isPendingCancel } = useCancelShiftMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.cancelShift'),
      });
      setIsOpen(false);
    },
  });

  const { mutate: resendSMSLinkForSign, isPending: isPendingResendLink } = useResendLinkForSignMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.smsSentSuccess'),
      });
    },
  });

  const { mutate: cancelAllShift, isPending: isPendingAllCancel } = useCancelAllShiftMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.cancelAllShift'),
      });
      setIsOpen(false);
      onUpdated?.();
    },
  });

  const { mutate: removeShift } = useRemoveShiftMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndoShift(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      setShiftId(undefined);
      setIsOpen(false);
      onUpdated?.();
      onRemove?.(data.id);
    },
  });

  const { mutate: removeUndoShift } = useRemoveUndoShiftMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      onUpdated?.();
    },
  });

  useImperativeHandle(ref, () => ({
    openById: (id: string) => {
      setIsOpen(true);
      setShiftId(id);
    },
  }));

  const handleEditShift = () => {
    setIsOpen(false);
    manageShiftDialogRef.current?.open(shiftDetails as Shift);
  };

  const handleResendLinkForSign = () => {
    resendSMSLinkForSign({ id: shiftDetails?.id as string });
  };

  const handleDeleteShift = () => {
    if (shiftId) {
      removeShift(shiftId);
      setIsOpen(false);
    }
  };

  const previewClient = (id: string) => {
    previewClientDialogRef.current?.openById(id);
  };

  const previewTherapist = (id: string) => {
    previewTherapistDialogRef.current?.openById(id);
  };

  const previewService = (id: string) => {
    previewServiceDialogRef.current?.openById(id, false);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-[1280px]">
          <DialogHeader>
            <DialogTitle className="flex flex-row items-center justify-start">
              {t('Pages.AllShifts.shiftDetails')}
              <div className="ml-4">
                {shiftDetails && <Chips color={SHIFT_STATUS_COLORS[shiftDetails.status]} title={shiftDetails.status} />}
              </div>
            </DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>

          <ScrollArea className="h-[calc(100vh-320px)] overflow-auto pr-5">
            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              <LabelValueItem label={t('Forms.clients.label')} withoutBorder={!!shiftDetails?.client}>
                {shiftDetails?.client && (
                  <Chips
                    color={Colors.indigo}
                    title={compileFullName(shiftDetails.client)}
                    onClickAction={() => previewClient(shiftDetails.client.id as string)}
                  />
                )}
              </LabelValueItem>
              <LabelValueItem label={t('Forms.therapist.label')} withoutBorder={!!shiftDetails?.therapist}>
                {shiftDetails?.therapist && (
                  <Chips
                    color={USER_ROLES_COLORS[RoleTypes.Therapist]}
                    title={compileFullName(shiftDetails.therapist)}
                    onClickAction={
                      checkPermissions([PermissionsTypes.PREVIEW_THERAPIST_PROFILE])
                        ? () => previewTherapist(shiftDetails.therapist.id as string)
                        : undefined
                    }
                  />
                )}
              </LabelValueItem>
              {shiftDetails?.service && (
                <LabelValueItem label={t('Forms.service.label')} withoutBorder>
                  <Chips
                    color={getColorForServiceRole(
                      shiftDetails?.serviceRole.title,
                      shiftDetails.serviceRole.speciality.code
                    )}
                    title={shiftDetails.service ? shiftDetails.service.name : '---'}
                    onClickAction={() => previewService(shiftDetails.service.id as string)}
                  />
                </LabelValueItem>
              )}
              <LabelValueItem label={t('Forms.serviceRole.label')} withoutBorder={!!shiftDetails?.serviceRole}>
                {shiftDetails?.serviceRole && (
                  <Chips
                    color={getColorForServiceRole(
                      shiftDetails.serviceRole.title,
                      shiftDetails.serviceRole.speciality.code
                    )}
                    title={shiftDetails.serviceRole.title}
                  />
                )}
              </LabelValueItem>
              {shiftDetails?.googleMeetUrl && shiftDetails.status === SHIFT_STATUS_TYPES.SCHEDULED ? (
                <LabelValueItem label={t('Forms.googleMeetUrl.label')}>
                  <Link
                    className="cursor-pointer text-sm text-primary underline"
                    href={shiftDetails.googleMeetUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {shiftDetails.googleMeetUrl}
                  </Link>
                </LabelValueItem>
              ) : null}
              <LabelValueItem
                label={t('Forms.date.label')}
                value={transformDateToViewDate(shiftDetails?.startAt, false)}
              />
              <LabelValueItem label={t('Forms.startTime.label')} value={shiftDetails?.startTime} />
              <LabelValueItem label={t('Forms.endTime.label')} value={shiftDetails?.endTime} />
              <LabelValueItem label={t('Forms.clockInTime.label')} value={shiftDetails?.clockIn} />
              <LabelValueItem label={t('Forms.clockOutTime.label')} value={shiftDetails?.clockOut} />
              {/* <LabelValueItem label={t('Forms.typeOfShift.label')} value={shiftDetails?.typeOfShift} />
              <LabelValueItem label={t('Forms.typeOfVisit.label')} value={shiftDetails?.typeOfVisit} /> */}
            </div>
            <div className="mt-6 flex w-full max-w-[676px] flex-col space-y-6">
              <LabelValueItem label={t('Forms.notes.label')} pre value={shiftDetails?.notes} />
            </div>
            <div className="my-6 grid grid-cols-form-cols-2 gap-8 pl-1">
              {shiftDetails?.clientSignatureUrl && (
                <div className="flex w-full flex-col">
                  <h4 className="ml-1 mt-1 select-none text-sm font-medium text-gray-700">
                    {' '}
                    {t('Forms.clientSignature.label')}{' '}
                  </h4>
                  <Image src={shiftDetails?.clientSignatureUrl} width={200} height={56} alt="Client signature" />
                </div>
              )}

              {shiftDetails?.assessmentFileUrl && (
                <div
                  onClick={() => window.open(shiftDetails?.assessmentFileUrl, '_blank', 'noopener,noreferrer')}
                  className="flex min-h-14 max-w-80 cursor-pointer flex-row items-center justify-start space-x-4 rounded-sm transition-colors duration-300 hover:bg-blue-50"
                >
                  <div className="flex flex-col items-center justify-center rounded-sm bg-blue-100 p-2">
                    <FileText className="h-8 w-8 text-blue-600" />
                  </div>
                  <span className="px-2 text-base">{t('Buttons.reportFile')}</span>
                </div>
              )}
            </div>
          </ScrollArea>

          <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-between space-x-2 border-t border-t-gray-300 sm:justify-between">
            {!shiftDetails?.deletedAt && (
              <>
                <div className="space-x-4">
                  {shiftDetails &&
                  ((checkPermissions([PermissionsTypes.SOFT_DELETE_SHIFT]) &&
                    shiftDetails.status !== SHIFT_STATUS_TYPES.CANCELED &&
                    shiftDetails.status !== SHIFT_STATUS_TYPES.NO_SHOW) ||
                    (checkPermissions([PermissionsTypes.SOFT_DELETE_SHIFT_NO_SHOW]) &&
                      shiftDetails.status !== SHIFT_STATUS_TYPES.CANCELED)) ? (
                    <ConfirmDialog onConfirm={() => handleDeleteShift()} title={t('Common.deleteShift')} type="delete">
                      <Button variant="outlineDestructive" type="button" size="lg" className="mt-5">
                        {t('Buttons.deleteShift')}
                      </Button>
                    </ConfirmDialog>
                  ) : null}
                  {shiftDetails &&
                  ((shiftDetails.status !== SHIFT_STATUS_TYPES.COMPLETED &&
                    shiftDetails.status !== SHIFT_STATUS_TYPES.CANCELED &&
                    checkPermissions([PermissionsTypes.CANCEL_SHIFT])) ||
                    (checkPermissions([PermissionsTypes.CANCEL_COMPLETED_SHIFT]) &&
                      shiftDetails.status !== SHIFT_STATUS_TYPES.CANCELED)) ? (
                    shiftDetails.recurrenceType === TypesOfShiftRepeat.SINGLE ? (
                      <ConfirmDialog
                        onConfirm={() => cancelShift(shiftDetails.id)}
                        title={t('Common.cancelShiftTitle')}
                        type="warning"
                        description={t('Common.confirmCancelShift')}
                      >
                        <Button
                          type="button"
                          variant="outlineDestructive"
                          size="lg"
                          className="mt-5"
                          disabled={isPendingCancel}
                        >
                          {t('Buttons.cancelShift')}
                        </Button>
                      </ConfirmDialog>
                    ) : (
                      <ConfirmDialog
                        onConfirm={() => cancelAllShift(shiftDetails.id)}
                        onCancel={() => cancelShift(shiftDetails.id)}
                        title={t('Common.cancelShiftTitle')}
                        type="warning"
                        okBtnName="All"
                        noBtnName="Only one"
                        description={t('Common.confirmCancelScheduleOrShift')}
                      >
                        <Button
                          type="button"
                          variant="outlineDestructive"
                          size="lg"
                          className="mt-5"
                          disabled={isPendingCancel || isPendingAllCancel}
                        >
                          {t('Buttons.cancelShift')}
                        </Button>
                      </ConfirmDialog>
                    )
                  ) : null}

                  {checkPermissions([PermissionsTypes.TRIGGER_SMS_FOR_SIGN_SHIFT]) &&
                  shiftDetails &&
                  shiftDetails.typeOfShift === SERVICE_TYPES.Ongoing &&
                  (shiftDetails.status === SHIFT_STATUS_TYPES.SCHEDULED ||
                    shiftDetails.status === SHIFT_STATUS_TYPES.COMPLETED ||
                    shiftDetails.status === SHIFT_STATUS_TYPES.PENDING) ? (
                    <Button
                      variant="outline"
                      type="button"
                      size="lg"
                      className="mt-5"
                      disabled={isPendingResendLink}
                      onClick={handleResendLinkForSign}
                    >
                      {t('Buttons.resendLinkForSign')}
                    </Button>
                  ) : null}
                </div>

                <div>
                  {(checkPermissions([PermissionsTypes.CHANGE_SHIFT_DETAILS]) &&
                    shiftDetails &&
                    shiftDetails.status !== SHIFT_STATUS_TYPES.COMPLETED &&
                    shiftDetails.status !== SHIFT_STATUS_TYPES.NO_SHOW &&
                    shiftDetails.status !== SHIFT_STATUS_TYPES.CANCELED) ||
                  (checkPermissions([PermissionsTypes.CHANGE_SHIFT_DETAILS_COMPLETED_NO_SHOW]) &&
                    shiftDetails &&
                    shiftDetails.status !== SHIFT_STATUS_TYPES.CANCELED) ? (
                    <Button type="button" size="lg" className="mt-5" onClick={handleEditShift}>
                      {t('Buttons.edit')}
                    </Button>
                  ) : null}
                </div>
              </>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <PreviewClientDialog onUpdated={refetchShift} ref={previewClientDialogRef} />
      <PreviewServiceDialog onUpdated={refetchShift} ref={previewServiceDialogRef} />
      <PreviewTherapistDialog onUpdated={refetchShift} ref={previewTherapistDialogRef} />
      <ManageShiftDialog
        onUpdated={() => {
          refetchShift();
          onUpdated?.();
        }}
        ref={manageShiftDialogRef}
      />
    </>
  );
});

PreviewShiftDialog.displayName = 'PreviewShiftDialog';

export { PreviewShiftDialog };
