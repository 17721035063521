import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as _ from 'lodash';
import { Shift } from '@/views/all-shifts/interfaces/shift.interface';
import { TagItem } from '@/components/form/tags-selector/tags-selector';
import { useIsAllowed } from '@/lib/RBAC';
import { PermissionsTypes } from '@/lib/RBAC/enums/permissions-types';
import { SHIFT_STATUS_TYPES, SHIFT_STATUSES } from '@/common/constants/common';

type Response = {
  data: Shift[];
  totalCount: number;
};
interface Params {
  skip: number;
  take: number;
  sortField?: string | null;
  sortOrder?: 'ASC' | 'DESC';
  search?: string;
  regionalCenter?: string;
  location?: string; // Online, In-Home
  serviceRoleType?: string; // Ongoing, Assessment
  isSigned?: boolean;
  startTime?: string; // This week, This month, This pay period, Previous week, Previous month, Previous pay period
  status?: TagItem[]; // Draft, Scheduled, Pending, Completed, No show, Canceled
  clientId?: string;
  therapistId?: string;
  serviceId?: string;
}

export type Options<T> = Omit<UseQueryOptions<unknown, DefaultError, T>, 'queryKey' | 'queryFn'>;

export const useShiftsQuery = <T = Response>(params: Params, options?: Options<T>) => {
  const { checkPermissions } = useIsAllowed();

  const queryParams = {
    skip: params.skip,
    take: params.take,
    sortField: params.sortField,
    sortOrder: params.sortField ? params.sortOrder : null,
    search: params.search,
    regionalCenterFilter: params.regionalCenter,
    locationFilter: params.location,
    serviceRoleTypeFilter: params.serviceRoleType,
    isSignedFilter: params.isSigned,
    startTimeFilter: params.startTime,
    'statusFilter[]': checkPermissions([PermissionsTypes.VIEW_CANCEL_SHIFTS])
      ? params.status?.map((value) => value.label)
      : params.status?.length
        ? params.status?.map((value) => value.label)
        : [
            SHIFT_STATUS_TYPES.COMPLETED,
            SHIFT_STATUS_TYPES.DRAFT,
            SHIFT_STATUS_TYPES.NO_SHOW,
            SHIFT_STATUS_TYPES.PENDING,
            SHIFT_STATUS_TYPES.SCHEDULED,
          ],
    clientIdFilter: params.clientId,
    therapistIdFilter: params.therapistId,
    serviceIdFilter: params.serviceId,
  };

  const cleanedQueryParams = _.omitBy(queryParams, (value) => _.isNil(value) || value === '');

  const _query = async (): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.get('shifts', {
      params: cleanedQueryParams,
    });
    return data;
  };

  return useQuery({
    queryKey: ['shifts', queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
