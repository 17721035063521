'use client';
import { Clock4, X } from 'lucide-react';
import { Button, Popover, PopoverContent, PopoverTrigger } from '@/components/ui';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/form';
import { DatePicker } from '@/components/form/date-picker/date-picker';
import { RecurringShiftValidationSchema, recurringShiftValidationSchema } from './recurring-shift-validation-schema';
import { DAYS_OF_MONTH, DAYS_OF_WEEK } from '@/common/constants/common';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TypesOfShiftRepeat } from '@/common/enums/common';
import dayjs from '@/lib/dayjsConfig';

export interface RecurringShiftData extends RecurringShiftValidationSchema {
  repeatOnWeek: number;
}

interface RecurringShiftProps {
  value: RecurringShiftData;
  className?: string;
  onChange: (value: RecurringShiftData) => void;
}

export function RecurringShift({ value, className, onChange }: RecurringShiftProps) {
  const t = useTranslations();
  const [open, setOpen] = useState(false);
  const [repeatOnWeek, setRepeatOnWeek] = useState(value && value.repeatOnWeek ? value.repeatOnWeek : 0);

  const form = useForm<z.infer<typeof recurringShiftValidationSchema>>({
    mode: 'onTouched',
    resolver: zodResolver(recurringShiftValidationSchema),
    defaultValues: {
      configureRepeat: value?.configureRepeat,
      repeatOnDay: value?.repeatOnDay,
      endsOn: dayjs(value?.endsOn) ? dayjs(value.endsOn) : dayjs(),
    },
  });

  const onSubmit: SubmitHandler<RecurringShiftValidationSchema> = async (value: RecurringShiftValidationSchema) => {
    onChange({
      ...value,
      repeatOnWeek,
    });
    setOpen(false);
  };

  const configureRepeatSelector = form.watch('configureRepeat');

  useEffect(() => {
    form.trigger();
  }, [configureRepeatSelector]);

  useEffect(() => {
    if (value.endsOn) {
      form.setValue('endsOn', dayjs(value.endsOn));
    }
  }, [value]);

  return (
    <div className={cn('flex flex-row items-center space-x-2', className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button type="button" variant="ghost" size="icon">
            <Clock4 className="h-5 w-5" />
          </Button>
        </PopoverTrigger>
        <PopoverContent closeByAction withoutPortal align="start" side="top" className="w-80">
          <header className="mb-4 flex flex-row items-center justify-between">
            <h2 className="text-xl font-semibold">{t('Common.recurringShift')}</h2>
            <Button type="button" variant="ghost" size="icon" onClick={() => setOpen(false)}>
              <X className="h-5 w-5" />
            </Button>
          </header>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="flex flex-col space-y-4">
                <div className="relative max-w-80">
                  <FormField
                    control={form.control}
                    name="configureRepeat"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>{t('Forms.configureRepeat.label')}</FormLabel>
                        <FormControl>
                          <Select value={field.value?.toString()} onValueChange={field.onChange}>
                            <FormControl>
                              <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                                <SelectValue placeholder={t('Forms.configureRepeat.placeholder')} />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value={TypesOfShiftRepeat.SINGLE}> {t('Common.single')} </SelectItem>
                              <SelectItem value={TypesOfShiftRepeat.WEEKLY}> {t('Common.weekly')} </SelectItem>
                              <SelectItem value={TypesOfShiftRepeat.MONTHLY}> {t('Common.monthly')} </SelectItem>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>

                {form.getValues('configureRepeat') === 'Weekly' && (
                  <>
                    <h4 className="mb-1 ml-1 select-none text-sm font-medium text-gray-700">
                      {t('Forms.repeatOn.label')}
                    </h4>
                    <div className="flex flex-row justify-between">
                      {DAYS_OF_WEEK.map((day, index) => (
                        <Button
                          key={day}
                          type="button"
                          size="icon"
                          variant={repeatOnWeek === index ? 'default' : 'outline'}
                          onClick={() => setRepeatOnWeek(index)}
                        >
                          {DAYS_OF_WEEK[index]}
                        </Button>
                      ))}
                    </div>
                  </>
                )}

                {form.getValues('configureRepeat') === 'Monthly' && (
                  <div className="relative max-w-80">
                    <FormField
                      control={form.control}
                      name="repeatOnDay"
                      render={({ field, fieldState }) => (
                        <FormItem>
                          <FormLabel>{t('Forms.repeatOn.label')}</FormLabel>
                          <FormControl>
                            <Select value={field.value?.toString()} onValueChange={field.onChange}>
                              <FormControl>
                                <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                                  <SelectValue placeholder={t('Forms.repeatOn.placeholder')} />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {DAYS_OF_MONTH.map((day) => (
                                  <SelectItem key={day} value={day.toString()}>
                                    {day}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage className="absolute" />
                        </FormItem>
                      )}
                    />
                  </div>
                )}

                
                <div className="relative max-w-80">
                  <FormField
                    control={form.control}
                    name="endsOn"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>{t('Forms.endsOn.label')}</FormLabel>
                        <DatePicker disableDates="none" field={field} isError={fieldState.error} />
                        <FormMessage className="absolute" />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <footer className="flex flex-row items-center justify-end space-x-2">
                <Button type="submit" size="lg" className="mt-8">
                  {t('Buttons.apply')}
                </Button>
              </footer>
            </form>
          </Form>
        </PopoverContent>
      </Popover>
    </div>
  );
}
