import { ALLOWED_DOMAIN, USER_NAME } from '@/common/constants/regex-constants';
import { validatePhone } from '@/components/form/phone-input/utils';
import { z } from 'zod';

export const manageTherapistValidationSchema = z
  .object({
    firstName: z
      .string()
      .min(1, { message: 'Forms.firstName.required' })
      .min(2, { message: 'Forms.firstName.min' })
      .max(50, { message: 'Forms.firstName.max' }),
      // .regex(USER_NAME, { message: 'Forms.firstName.onlyAlphabetic' }),
    lastName: z
      .string()
      .min(1, { message: 'Forms.lastName.required' })
      .min(2, { message: 'Forms.lastName.min' })
      .max(50, { message: 'Forms.lastName.max' }),
      // .regex(USER_NAME, { message: 'Forms.lastName.onlyAlphabetic' }),
    email: z
      .string({ message: 'Forms.email.required' })
      .min(1, { message: 'Forms.email.required' })
      .max(100, { message: 'Forms.email.max' })
      .email('Forms.invalid')
      .refine((value) => value.endsWith(ALLOWED_DOMAIN), {
        message: 'Forms.email.wrongDomain',
      }),
    phoneNumber: z.string().min(1, { message: 'Forms.phoneNumber.required' }),
    languages: z.array(
      z.object({
        label: z.string(),
        value: z.any(),
      })
    ),
    role: z.string().min(1, { message: 'Forms.accessLevel.required' }),
    serviceRoleIdList: z.array(
      z.object({
        label: z.string(),
        value: z.any(),
      })
    ),
    supervisor: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.phoneNumber && data.phoneNumber.length > 0) {
      if (!validatePhone(data.phoneNumber)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['phoneNumber'],
          message: 'Forms.phoneNumber.invalid',
        });
      }
    }
  });

export type ManageTherapistValidationSchema = z.infer<typeof manageTherapistValidationSchema>;
