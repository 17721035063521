'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  PhoneInput,
  TagsSelector,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui';
import { ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import { DialogFooter } from '@/components/ui/dialog';
import { useEffect, useState } from 'react';
import { Client, Guardian } from '../interfaces/guardians.interface';
import { manageGuardianValidationSchema, ManageGuardianValidationSchema } from './manage-guardian-validation-schema';
import { useCreateGuardianMutation } from '@/hooks/fetchers/mutations/guardians/useCreateGuardianMutation';
import { useUpdateGuardianMutation } from '@/hooks/fetchers/mutations/guardians/useUpdateGuardianMutation';
import { LANGUAGES_TAGS, RELATIONSHIPS } from '@/common/constants';
import { useClientsQuery } from '@/hooks/fetchers/queries/clients/useClientsQuery';
import { TagItem } from '@/components/form/tags-selector/tags-selector';
import { PHONE_COUNTRIES } from '@/common/constants/common';

const LANGUAGES = LANGUAGES_TAGS();

interface Props {
  guardian?: Guardian;
  onDelete?: (value: ManageGuardianValidationSchema) => void;
  onCancel?: () => void;
  onRefresh: () => void;
}

export function ManageGuardianForm({ guardian, onDelete, onCancel, onRefresh }: Props) {
  const t = useTranslations();
  const [keywordClient, setKeywordClient] = useState('');
  const {
    data: clients,
    isLoading: isLoadingClients,
  } = useClientsQuery<TagItem[]>(
    {
      search: keywordClient,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((client: Client) => ({ label: `${client.firstName} ${client.lastName}`, value: client })),
    }
  );

  const onSearchByClients = (value: string) => {
    setKeywordClient(value);
  };

  const { mutate: create, isPending: isCreating } = useCreateGuardianMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.guardianCreated'),
      });
      form.reset();
      onRefresh();
    },
  });

  const { mutate: update, isPending: isUpdating } = useUpdateGuardianMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.guardianUpdated'),
      });
      onRefresh();
    },
  });

  const form = useForm<ManageGuardianValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(manageGuardianValidationSchema),
    defaultValues: {
      firstName: guardian?.firstName ?? '',
      lastName: guardian?.lastName ?? '',
      email: guardian?.email ?? '',
      primaryNumber: guardian?.primaryNumber ?? '',
      secondaryNumber: guardian?.secondaryNumber ?? '',
      primaryRelationship: guardian?.primaryRelationship ?? '',
      secondaryRelationship: guardian?.secondaryRelationship ?? '',
      clientIds: guardian
        ? guardian.clients.map((client: Client) => ({ label: `${client.firstName} ${client.lastName}`, value: client }))
        : [],
      languages: guardian ? guardian.languages.map((lang: string) => ({ label: lang, value: { id: lang } })) : [],
    },
  });

  const handleDelete = () => {
    if (onDelete) {
      onDelete(form.getValues());
    }
  };

  const onSubmit: SubmitHandler<ManageGuardianValidationSchema> = async (value: ManageGuardianValidationSchema) => {
    if (guardian) {
      update({
        id: guardian.id,
        value,
      });
    } else {
      create(value);
    }
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <ScrollArea className="h-[calc(100vh-300px)] overflow-auto">
            <h3 className="mb-4 text-base font-semibold text-gray-900">{t('Pages.AllGuardians.guardianDetails')}</h3>
            <div className="ml-1 grid grid-cols-form-cols-2 gap-8">
              <div className="relative">
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel isRequired>{t('Forms.firstName.label')}</FormLabel>
                      <FormControl>
                        <Input
                          hasError={!!fieldState.error}
                          className="w-full"
                          placeholder={t('Forms.firstName.placeholder')}
                          {...field}
                          type="text"
                        />
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>

              <div className="relative">
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel isRequired>{t('Forms.lastName.label')}</FormLabel>
                      <FormControl>
                        <Input
                          hasError={!!fieldState.error}
                          className="w-full"
                          {...field}
                          type="text"
                          placeholder={t('Forms.lastName.placeholder')}
                        />
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>

              <div className="relative">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>{t('Forms.email.label')}</FormLabel>
                      <FormControl>
                        <Input
                          hasError={!!fieldState.error}
                          className="w-full"
                          placeholder={t('Forms.email.placeholder')}
                          {...field}
                          type="text"
                        />
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>

              <div className="relative">
                <FormField
                  control={form.control}
                  name="primaryNumber"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel isRequired>{t('Forms.primaryNumber.label')}</FormLabel>
                      <FormControl>
                        <PhoneInput
                          countries={PHONE_COUNTRIES}
                          placeholder={t('Forms.phoneNumber.placeholder')}
                          {...field}
                          className="w-full"
                          hasError={!!fieldState.error}
                        />
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>

              <div className="relative">
                <FormField
                  control={form.control}
                  name="languages"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel isRequired>{t('Forms.languages.label')}</FormLabel>
                      <FormControl>
                        <TagsSelector
                          data={LANGUAGES}
                          values={(field.value ? field.value : []) as TagItem[]}
                          onChoose={field.onChange}
                          placeholder={t('Forms.languages.label')}
                          className={cn('', !!fieldState.error && 'border-destructive')}
                        />
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>

              <div className="relative">
                <FormField
                  control={form.control}
                  name="clientIds"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>{t('Forms.clients.label')}</FormLabel>
                      <FormControl>
                        <TagsSelector
                          search
                          data={clients ?? []}
                          onOpen={() => setKeywordClient('')}
                          onChangeSearch={onSearchByClients}
                          values={field.value ? (field.value as TagItem[]) : []}
                          onChoose={field.onChange}
                          placeholder={isLoadingClients ? t('Common.loadingWait') : t('Forms.clients.label')}
                          className={cn('', !!fieldState.error && 'border-destructive')}
                        />
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>

              <div className="relative">
                <FormField
                  control={form.control}
                  name="primaryRelationship"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>{t('Forms.primaryRelationship.label')}</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={field.onChange}>
                          <FormControl>
                            <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                              <SelectValue placeholder={t('Forms.primaryRelationship.placeholder')} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value={null as any}>None</SelectItem>
                            {RELATIONSHIPS.map((value) => (
                              <SelectItem key={value} value={value}>
                                {value}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <h3 className="my-4 text-base font-semibold text-gray-900">{t('Pages.AllGuardians.additionalDetails')}</h3>

            <div className="ml-1 grid grid-cols-form-cols-2 gap-8">
              <div className="relative">
                <FormField
                  control={form.control}
                  name="secondaryNumber"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>{t('Forms.secondaryNumber.label')}</FormLabel>
                      <FormControl>
                        <PhoneInput
                          countries={PHONE_COUNTRIES}
                          placeholder={t('Forms.phoneNumber.placeholder')}
                          {...field}
                          className="w-full"
                          hasError={!!fieldState.error}
                        />
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>

              <div className="relative">
                <FormField
                  control={form.control}
                  name="secondaryRelationship"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>{t('Forms.secondaryRelationship.label')}</FormLabel>
                      <FormControl>
                        <Select
                          value={field.value}
                          onValueChange={(e) => {
                            field.onChange(e);
                          }}
                        >
                          <FormControl>
                            <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                              <SelectValue placeholder={t('Forms.secondaryRelationship.placeholder')} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value={null as any}>None</SelectItem>
                            {RELATIONSHIPS.map((value) => (
                              <SelectItem key={value} value={value}>
                                {value}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>
            </div>
        </ScrollArea>

        <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
          {guardian ? (
            <Button onClick={handleDelete} variant="outlineDestructive" type="button" size="lg" className="mt-5">
              {t('Buttons.deleteGuardianAccount')}
            </Button>
          ) : (
            <Button onClick={onCancel} variant="ghost" type="button" size="lg" className="mt-5">
              {t('Buttons.cancel')}
            </Button>
          )}

          <Button type="submit" size="lg" className="mt-5" disabled={isCreating || isUpdating}>
            {t('Buttons.save')}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
