import { z } from 'zod';

export const updatePayRatesValidationSchema = z.object({
  generalBasePayRate: z
    .preprocess(
      (val) => Number(String(val)),
      z.number().min(0, {
        message: 'Forms.generalBasePayRate.min',
      })
    )
    .refine((val) => !isNaN(Number(val)), { message: 'Forms.generalBasePayRate.invalid' }),
  payRates: z.array(
    z.object({
      serviceRoleName: z.string().optional(),
      payRate: z.preprocess(
        (val) => Number(String(val)),
        z.number().min(0, {
          message: 'Forms.payRate.min',
        })
      ).refine(     
        (val) => !isNaN(Number(val)),
        { message: 'Forms.payRate.invalid' }
      ),
      serviceRoleId: z.string(),
    })
  ),
});

export type UpdatePayRatesValidationSchema = z.infer<typeof updatePayRatesValidationSchema>;
