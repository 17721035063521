import { cn } from '@/lib/utils';
import { cva, VariantProps } from 'class-variance-authority';
import Image from 'next/image';

interface Props extends VariantProps<typeof badgeVariants> {
  label: string;
  value: string | number;
  iconWidth?: number;
  iconHeight?: number;
  svgPath: string;
  className?: string;
  classNameIcon?: string;
}

const badgeVariants = cva('flex flex-row items-center rounded-md border border-gray-200', {
  variants: {
    size: {
      default: 'h-14 min-w-44',
      lg: 'h-20 min-w-56',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export function StatisticBadge({ label, value, svgPath, className, classNameIcon, size, iconWidth = 32, iconHeight = 32 }: Props) {
  return (
    <div className={cn(badgeVariants({ size, className }))}>
      <Image className={cn('ml-2 mr-3 rounded-md bg-blue-50 p-1', classNameIcon)} width={iconWidth} height={iconHeight} alt="Badge Icon" src={svgPath} />
      <div className="flex flex-col p-2">
        <p className="text-xl font-bold text-gray-800">{value}</p>
        <span className="text-sm font-normal text-gray-500">{label}</span>
      </div>
    </div>
  );
}
