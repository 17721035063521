import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash';

type Request = {
  therapistId: string;
  value: {
    generalBasePayRate: number;
    payRates: {
      serviceRoleId: string;
      payRate: number;
    }[];
  };
};

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useUpdatePayRatesMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async ({ therapistId, value }: Request): Promise<string> => {
    const { data } = await axiosInterceptorInstance.patch(`pay-rates/${therapistId}`, value);
    queryClient.invalidateQueries({ queryKey: ['therapists', therapistId] });
    queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
    return data;
  };

  return useMutation({
    mutationKey: ['pay-rates'],
    mutationFn: _mutation,
    ...options,
  });
};
