import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

export type Options = Omit<
  UseMutationOptions<{ shiftsArchivedMessage: string }, DefaultError, string>,
  'mutationKey' | 'mutationFn'
>;

export const useApproveAuthorizationMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (serviceId: string): Promise<{ shiftsArchivedMessage: string }> => {
    const { data } = await axiosInterceptorInstance.patch(`services/me/clients/${serviceId}/approve-authorization`);
    return data;
  };

  return useMutation({
    mutationKey: ['services', 'me', 'clients'],
    onSettled: (data, error) => {
      if (!error) {
        queryClient.resetQueries({ queryKey: ['services'] });
        queryClient.invalidateQueries({ queryKey: ['clients'] });
        queryClient.invalidateQueries({ queryKey: ['therapists'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    },
    mutationFn: _mutation,
    ...options,
  });
};
