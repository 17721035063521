import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { Role } from '@/common/interfaces';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';

export enum RoleLevels {
  ALL = 'all',
  THERAPIST = 'therapist',
  SUPERVISOR = 'supervisor',
  ADMIN = 'admin',
}
export type Options<T> = Omit<UseQueryOptions<unknown, DefaultError, T>, 'queryKey' | 'queryFn'>;

export const useRolesQuery = <T = Role[]>(level: RoleLevels = RoleLevels.ALL, options?: Options<T>) => {
  const _query = async (): Promise<T> => {
    const { data } = await axiosInterceptorInstance.get('roles', {
      params: { level },
    });
    return data;
  };

  return useQuery({
    queryKey: ['roles', level],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
