import { ScrollArea } from '@/components/common';
import { SimpleSearch } from '@/components/common/table/simple-search';
import { Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useAdpIdsQuery } from '@/hooks/fetchers/queries/therapists/useAdpIdsQuery';

interface ChooseAdpIdDialogProps {
  handleChoose: (value: { id: string; name: string }) => void;
}

export type ChooseAdpIdDialogRef = {
  open: () => void;
};

const ChooseAdpIdDialog = forwardRef<ChooseAdpIdDialogRef, ChooseAdpIdDialogProps>(({ handleChoose }, ref) => {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [keyword, setKeyword] = useState('');

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
  }));

  useEffect(() => {
    setKeyword('');
  }, [isOpen]);

  const { data: workers, isLoading } = useAdpIdsQuery({
    enabled: true,
    refetchOnWindowFocus: isOpen,
  });

  const filterBySearch = (data?: { id: string; name: string }[]) => {
    const value = keyword || '';
    const regex = new RegExp(value, 'i');
    return data ? data.filter((user) => regex.test(user.name)) : [];
  };

  const filtered = filterBySearch(workers);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="min-w-[600px]">
        <DialogHeader>
          <DialogTitle>{t('Pages.AllTherapists.chooseADPID')}</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        <div className="h-[calc(100vh-228px)]">
          <SimpleSearch className="w-full" onSearchChange={(e) => setKeyword(e.target.value)} />
          <ul className="mt-6">
            <ScrollArea className="h-[calc(100vh-336px)]">
              {filtered?.map((worker) => (
                <li
                  onClick={() => {
                    handleChoose(worker);
                    setIsOpen(false);
                  }}
                  className={cn('border-grey-lighter flex min-h-9 cursor-pointer items-center border-b px-3')}
                  key={worker.id}
                >
                  <span className="font-semibold">{worker.id}</span> <span className="ml-2">({worker.name})</span>
                </li>
              ))}
            </ScrollArea>
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
});

ChooseAdpIdDialog.displayName = 'ChooseAdpIdDialog';

export { ChooseAdpIdDialog };
