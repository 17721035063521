'use client';
import { useEffect, useState } from 'react';
import { Grip, ListTodo } from 'lucide-react';
import { Sheet, SheetContent, SheetDescription, SheetTitle, SheetTrigger } from '@/components/common';
import { Button } from '@/components/ui';
import { DragDropContext, Droppable, Draggable, type DropResult } from '@hello-pangea/dnd';
import { Column } from '@tanstack/react-table';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { useTranslations } from 'next-intl';

export interface ColumnAdvance<T> extends Column<T> {
  isVisible?: boolean;
}

interface TableColumnSettingsProps<T> {
  columnsIds: ColumnAdvance<T>[];
  onChange: (columnsIds: ColumnAdvance<T>[]) => void;
}

export function TableColumnSettings<T>({ columnsIds, onChange }: TableColumnSettingsProps<T>) {
  const t = useTranslations();
  const [columns, setColumns] = useState(columnsIds);
  const [createSheetOpen, setCreateSheetOpen] = useState(false);

  useEffect(() => {
    setColumns(columnsIds);
  }, [JSON.stringify(columnsIds.map((col) => ({ id: col.id, isVisible: col.getIsVisible() })))]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return; // Early exit if not dropped in a valid area

    const items = columns; // Make a copy of the chapters array to avoid mutation
    const [reorderedItem] = items.splice(result.source.index, 1); // Remove item from source
    items.splice(result.destination.index, 0, reorderedItem); // Insert item at destination

    setColumns([...items]); // Update the state with the reordered chapters
  };

  const handleSelect = (columnId: string) => {
    const _columns = [...columns];
    const index = _columns.findIndex((column) => column.id === columnId);
    if (index !== -1) {
      _columns[index].isVisible = !_columns[index].isVisible;
    }
    setColumns(_columns);
  };

  return (
    <Sheet open={createSheetOpen} onOpenChange={setCreateSheetOpen}>
      <SheetTrigger asChild>
        <Button className="min-w-10" size="iconSm">
          <ListTodo />
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetTitle>
          <VisuallyHidden.Root>Table Settings</VisuallyHidden.Root>
        </SheetTitle>
        <SheetDescription></SheetDescription>
        <header className="flex h-16 flex-row items-center bg-primary p-6">
          <h3 className="text-base font-medium text-white">{t('Common.tableSettings')}</h3>
        </header>
        <div className="h-[calc(100vh-144px)] overflow-auto w-full px-6 pt-6">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div className="mt-6" ref={provided.innerRef} {...provided.droppableProps}>
                  {columns.map((column, index) => (
                    <Draggable key={column.id} draggableId={column.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="mb-4 flex items-center gap-x-2 rounded-md border bg-white text-sm"
                        >
                          <div
                            className="cursor-grab rounded-l-md border-r bg-white px-2 py-3 transition hover:bg-neutral-100"
                            {...provided.dragHandleProps}
                          >
                            <Grip className="h-5 w-5" />
                          </div>
                          <div className="flex w-full flex-row items-center justify-between">
                            <span> {column.id} </span>

                            <div className="flex items-center px-4">
                              <input
                                className="h-4 w-4 cursor-pointer rounded-sm border-gray-300 bg-gray-100 text-blue-600"
                                {...{
                                  type: 'checkbox',
                                  checked: column.isVisible,
                                  onChange: () => {
                                    handleSelect(column.id);
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <footer className="flex h-20 flex-row items-center justify-between space-x-2 px-6">
          <Button onClick={() => setCreateSheetOpen(false)} variant="ghost" type="button" size="lg" className="w-full">
            {t('Buttons.cancel')}
          </Button>
          <Button
            type="button"
            onClick={() => {
              onChange(columns);
              setCreateSheetOpen(false);
            }}
            size="lg"
            className="w-full"
          >
            {t('Buttons.apply')}
          </Button>
        </footer>
      </SheetContent>
    </Sheet>
  );
}
