import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

export enum UserTypes {
  ADMINS = 'admins',
  THERAPIST = 'therapists',
  GUARDIAN = 'guardians',
}
interface RequestData {
  id: string;
  type: UserTypes;
}

export type Options = Omit<UseMutationOptions<string, DefaultError, RequestData>, 'mutationKey' | 'mutationFn'>;

export const useUserResendLinkMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: RequestData): Promise<string> => {
    const { data } = await axiosInterceptorInstance.post(`${value.type}/${value.id}/resend-invitation`);
    return data;
  };

  return useMutation({
    mutationKey: ['resend-invitation'],
    mutationFn: _mutation,
    onSettled: ((data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['admins'] });
        queryClient.invalidateQueries({ queryKey: ['therapists'] });
        queryClient.invalidateQueries({ queryKey: ['guardians'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    }),
    ...options,
  });
};
