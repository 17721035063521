import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ManageGuardianValidationSchema } from '@/views/all-guardians/manage/manage-guardian-validation-schema';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash';

type Request = {
  id: string;
  value: ManageGuardianValidationSchema;
};

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useUpdateGuardianMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async ({ id, value }: Request): Promise<string> => {
    const { firstName, lastName, email, primaryRelationship, secondaryRelationship } = value;
    const parsedValue = {
      firstName,
      lastName,
      email: email === '' ? null : email,
      primaryRelationship: primaryRelationship === '' ? null : primaryRelationship,
      secondaryRelationship: secondaryRelationship === '' ? null : secondaryRelationship,
      primaryNumber: value.primaryNumber.replace('+', ''),
      secondaryNumber: value.secondaryNumber ? value.secondaryNumber.replace('+', '') : null,
      languages: value.languages.map((tag) => tag.label),
      clientIds: value.clientIds.map((tag) => tag.value.id),
    };

    const body = _.omitBy(parsedValue, (v) => v === '');
    const { data } = await axiosInterceptorInstance.put(`guardians/${id}`, body);
    queryClient.invalidateQueries({ queryKey: ['guardians', id] });
    queryClient.invalidateQueries({ queryKey: ['guardians'] });
    queryClient.invalidateQueries({ queryKey: ['clients'] });
    queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
    return data;
  };

  return useMutation({
    mutationKey: ['guardians'],
    mutationFn: _mutation,
    ...options,
  });
};
