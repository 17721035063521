import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ShiftDetails } from '@/views/all-shifts/interfaces/shift.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import dayjs from '@/lib/dayjsConfig';

export type Options = Omit<UseQueryOptions<unknown, DefaultError, ShiftDetails | null>, 'queryKey' | 'queryFn'>;

export const useShiftQuery = (id?: string, options?: Options) => {
  const _query = async (): Promise<ShiftDetails | null> => {
    if (id) {
      const { data } = await axiosInterceptorInstance.get(`shifts/${id}`);
      if (data) {
        if (data.startAt) {
          data.startTime = dayjs(data.startAt).utcOffset(dayjs().format('Z')).format('h:mm A');
        }
        if (data.endAt) {
          data.endTime = dayjs(data.endAt).utcOffset(dayjs().format('Z')).format('h:mm A');
        }
        if (data.clockIn) {
          data.clockIn = dayjs(data.clockIn).utcOffset(dayjs().format('Z')).format('h:mm A');
        }
        if (data.clockOut) {
          data.clockOut = dayjs(data.clockOut).utcOffset(dayjs().format('Z')).format('h:mm A');
        }
      }
      return data;
    } else {
      return null;
    }
  };

  return useQuery({
    queryKey: ['shifts', id],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
