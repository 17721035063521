import { z } from 'zod';
import dayjs, { Dayjs } from 'dayjs';

export const createManualDocumentValidationSchema = z
  .object({
    therapist: z.string().optional(), 
    service: z.string().optional(), 
    client: z.string().min(1, { message: 'Forms.client.required' }), 
    dueDate: z.union([
      z.string().length(0), // Allows empty string
      z.custom<Dayjs>(
        (value) => {
          return dayjs.isDayjs(value);
        },
        { message: 'Forms.dueDate.required' }
      )
    ]),
    status: z.string().min(1, { message: 'Forms.status.required' }),
    documentType: z.string().min(1, { message: 'Forms.documentType.required' }),
  });

export type CreateManualDocumentValidationSchema = z.infer<typeof createManualDocumentValidationSchema>;
