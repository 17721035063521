import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';

export type Options = Omit<
  UseQueryOptions<unknown, DefaultError, { id: string; name: string }[]>,
  'queryKey' | 'queryFn'
>;

export const useAdpIdsQuery = (options?: Options) => {
  const _query = async (): Promise<{ id: string; name: string }[]> => {
    const { data } = await axiosInterceptorInstance.get(`payroll/workers`);
    return data.data;
  };

  return useQuery({
    queryKey: ['payroll', 'workers'],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
