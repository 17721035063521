'use client';

import { cn } from '@/lib/utils';

interface Params {
  onChange: () => void;
  isActive: boolean;
  label: string;
  className?: string;
}

export function ToggleFilter({ label, isActive, onChange, className }: Params) {
  return (
    <div
      className={cn(
        'flex w-full transition-colors cursor-pointer flex-row items-center justify-center rounded-sm bg-secondary/90 hover:bg-secondary px-1 text-sm text-gray-400',
        isActive && 'bg-primary/90 hover:bg-primary text-white',
        className
      )}
      onClick={onChange}
    >
      {label}
    </div>
  );
}
