import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ManageGuardianValidationSchema } from '@/views/all-guardians/manage/manage-guardian-validation-schema';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash';



export type Options = Omit<UseMutationOptions<string, DefaultError, ManageGuardianValidationSchema>, 'mutationKey' | 'mutationFn'>;

export const useCreateGuardianMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: ManageGuardianValidationSchema): Promise<string> => {
    const { firstName, lastName, email, primaryRelationship, secondaryRelationship } = value;
    const parsedValue = {
      firstName,
      lastName,
      email,
      primaryRelationship,
      secondaryRelationship,
      primaryNumber: value.primaryNumber.replace('+', ''),
      secondaryNumber: value.secondaryNumber.replace('+', ''),
      languages: value.languages.map((tag) => tag.label),
      clientIds: value.clientIds.map((tag) => tag.value.id),
    };
    const body = _.omitBy(parsedValue, v => _.isNil(v) || v === '');
    const { data } = await axiosInterceptorInstance.post(`guardians`, body);
    return data;
  };

  return useMutation({
    mutationKey: ['guardians'],
    mutationFn: _mutation,
    onSettled: (data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['guardians'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    },
    ...options,
  });
};
