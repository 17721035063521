import { Dialog } from '@/components/ui';
import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { DialogDescription } from '@radix-ui/react-dialog';

import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { ConfirmDialog } from '@/components/common';
import { ConfirmDialogRef } from '@/components/common/confirm-dialog';
import { Service } from '../interfaces/services.interface';
import { CreateServiceForm } from './create-service-form';
import { UpdateServiceForm } from './update-service-form';

interface Props {
  onUpdated: () => void;
  onDelete: (id: string) => void;
}

export type ManageServiceDialogRef = {
  open: (service: Service) => void;
};

const ManageServiceDialog = forwardRef<ManageServiceDialogRef, Props>(({ onUpdated, onDelete }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const accountRef = useRef<Service | null>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>();

  useImperativeHandle(ref, () => ({
    open: (service) => {
      setIsOpen(true);
      accountRef.current = service;
    },
  }));

  const handleDeleteAccount = () => {
    if (accountRef.current && accountRef.current.id) {
      const value = { ...accountRef.current };
      confirmDialogRef.current?.open({
        description: t('Toasts.confirmDeleteService', { name: `${value.title}` }),
        value: value.id,
      });
    }
  };

  const handleUpdateAccount = () => {
    setIsOpen(false);
    onUpdated();
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className='w-full max-w-[1280px]'>
          <DialogHeader>
            <DialogTitle>{accountRef.current ? t('Pages.AllServices.editService'): t('Pages.AllServices.previewService')  } </DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <div className="pt-4">
            {accountRef.current && (
              <UpdateServiceForm
                service={accountRef.current}
                onDelete={handleDeleteAccount}
                onRefresh={handleUpdateAccount}
              />
            )}
            {!accountRef.current && (
              <CreateServiceForm
                onRefresh={handleUpdateAccount}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        ref={confirmDialogRef}
        onConfirm={(id) => {
          onDelete(id);
          setIsOpen(false);
        }}
        title={t('Pages.AllServices.deleteService')}
        type="delete"
      />
    </>
  );
});

ManageServiceDialog.displayName = 'ManageServiceDialog';

export { ManageServiceDialog };
