import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { Document } from '@/views/all-documents/interfaces/document.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';

export type Options = Omit<UseQueryOptions<unknown, DefaultError, Document | null>, 'queryKey' | 'queryFn'>;

export const useDocumentQuery = (id?: string, options?: Options) => {
  const _query = async (): Promise<Document | null> => {
    if (id) {
      const { data } = await axiosInterceptorInstance.get(`documents/${id}`);
      return data;
    } else {
      return null;
    }
  };
 
  return useQuery({
    queryKey: ['documents', id],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
