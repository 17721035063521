import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

export type Options = Omit<UseMutationOptions<string, DefaultError, string>, 'mutationKey' | 'mutationFn'>;

export const useApproveDocumentMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (id: string): Promise<string> => {
    const { data } = await axiosInterceptorInstance.post(`documents/${id}/approve`);
    return data;
  };

  return useMutation({
    mutationKey: ['documents', 'approve'],
    mutationFn: _mutation,
    onSettled: ((data, error) => {
      if (!error) {
      queryClient.invalidateQueries({ queryKey: ['documents'] });
      queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    }),
    ...options,
  });
};
