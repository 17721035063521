import { USER_NAME } from '@/common/constants/regex-constants';
import { validatePhone } from '@/components/form/phone-input/utils';
import { z } from 'zod';

export const manageGuardianValidationSchema = z
  .object({
    firstName: z
      .string()
      .min(1, { message: 'Forms.firstName.required' })
      .min(2, { message: 'Forms.firstName.min' })
      .max(50, { message: 'Forms.firstName.max' }),
      // .regex(USER_NAME, { message: 'Forms.firstName.onlyAlphabetic' }),
    lastName: z
      .string()
      .min(1, { message: 'Forms.lastName.required' })
      .min(2, { message: 'Forms.lastName.min' })
      .max(50, { message: 'Forms.lastName.max' }),
      // .regex(USER_NAME, { message: 'Forms.lastName.onlyAlphabetic' }),
    email: z.union([z.literal(''), z.string().max(100, { message: 'Forms.email.max' }).email('Forms.invalid')]),
    primaryNumber: z.string().min(1, { message: 'Forms.primaryNumber.required' }),
    secondaryNumber: z.string(),
    primaryRelationship: z.string(),
    secondaryRelationship: z.string(),
    clientIds: z.array(
      z.object({
        label: z.string(),
        value: z.any(),
      })
    ),
    languages: z.array(
      z.object({
        label: z.string(),
        value: z.any(),
      })
    ).min(1, {message: 'Forms.languages.required'}),
  })
  .superRefine((data, ctx) => {
    if (data.primaryNumber && data.primaryNumber.length > 0) {
      if (!validatePhone(data.primaryNumber)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['primaryNumber'],
          message: 'Forms.phoneNumber.invalid',
        });
      }
    }
    if (data.secondaryNumber && data.secondaryNumber.length > 0) {
      if (!validatePhone(data.secondaryNumber)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['secondaryNumber'],
          message: 'Forms.phoneNumber.invalid',
        });
      }
    }
  });

export type ManageGuardianValidationSchema = z.infer<typeof manageGuardianValidationSchema>;
