import { Dialog } from '@/components/ui';
import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { DialogDescription } from '@radix-ui/react-dialog';

import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { CreateDocumentViaTemplateForm } from './create-document-via-template-form';
import { Document } from '../interfaces/document.interface';

interface Props {
  onCreated?: (document: Document) => void;
}

export type CreateDocumentViaTemplateDialogRef = {
  open: () => void;
};

const CreateDocumentViaTemplateDialog = forwardRef<CreateDocumentViaTemplateDialogRef, Props>(({ onCreated }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    },
  }));

  const handleCreate = (document: Document) => {
    setIsOpen(false);
    onCreated?.(document);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-[1280px]">
          <DialogHeader>
            <DialogTitle>{t('Pages.AllDocuments.createDocument')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <div className="pt-4">
            <CreateDocumentViaTemplateForm
              onCancel={() => setIsOpen(false)}
              onCreate={handleCreate}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
});

CreateDocumentViaTemplateDialog.displayName = 'CreateDocumentViaTemplateDialog';

export { CreateDocumentViaTemplateDialog };
