import { DATE_MMDDYYYY } from '@/common/constants/regex-constants';
import { z } from 'zod';
import dayjs from '@/lib/dayjsConfig';

export const createServiceValidationSchema = z
  .object({
    client: z.string().min(1, { message: 'Forms.client.required' }),
    serviceRole: z.string().min(1, { message: 'Forms.serviceRole.required' }),
    title: z.string().min(1, { message: 'Forms.title.required' }),
    guardians: z.array(
      z.object({
        label: z.string(),
        value: z.any(),
      })
    ),
    authorizedTo: z
      .string()
      .min(1, { message: 'Forms.authorizedTo.required' })
      .regex(DATE_MMDDYYYY, { message: 'Forms.authorizedTo.invalid' }), // MM.DD.YYYY
    authorizedFrom: z.union([
      z.string().length(0), // Allows empty string
      z.string().regex(DATE_MMDDYYYY, { message: 'Forms.authorizedFrom.invalid' }),
    ]),
    address: z
      .string()
      .min(1, { message: 'Forms.address.required' })
      .min(5, { message: 'Forms.address.min' })
      .max(200, { message: 'Forms.address.max' }),
    languages: z
      .array(
        z.object({
          label: z.string(),
          value: z.any(),
        })
      )
      .min(1, { message: 'Forms.languages.required' }),
    jobRoleType: z.string().optional(),
    monthly: z.number(),
    frequency: z.string().min(1, { message: 'Forms.frequency.required' }),
    therapist: z.string(),
    programNumber: z.preprocess((val) => Number(String(val)), z.number()).optional(),
    inPersonOnly: z.boolean().default(false).optional(),
    geofencing: z.boolean().default(false).optional(),
  })
  .superRefine((data, ctx) => {
    if (data.authorizedTo) {
      const dateToCheck = dayjs(data.authorizedTo);
      const today = dayjs().add(-1, 'day');
      const isPast = dateToCheck.isBefore(today);
      if (isPast) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['authorizedTo'],
          message: 'Forms.authorizedTo.min',
        });
      }
    }
  });

export type CreateServiceValidationSchema = z.infer<typeof createServiceValidationSchema>;
