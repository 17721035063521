import { z } from 'zod';
import dayjs, { Dayjs } from 'dayjs';

export const recurringShiftValidationSchema = z.object({
  configureRepeat: z.string(),
  endsOn: z.custom<Dayjs>(
    (value) => {
      return dayjs.isDayjs(value);
    },
    { message: 'Forms.endsOn.required' }
  ),
  repeatOnDay: z.string().optional(),
});

export type RecurringShiftValidationSchema = z.infer<typeof recurringShiftValidationSchema>;
