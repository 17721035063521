import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

export type Options = Omit<UseMutationOptions<string, DefaultError, string>, 'mutationKey' | 'mutationFn'>;

export const useRejectDocumentMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (id: string): Promise<string> => {
    const { data } = await axiosInterceptorInstance.post(`documents/${id}/reject`);
    return data;
  };

  return useMutation({
    mutationKey: ['documents', 'reject'],
    onSettled: ((data, error) => {
      queryClient.invalidateQueries({ queryKey: ['documents'] });
      queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
    }),
    mutationFn: _mutation,
    ...options,
  });
};
