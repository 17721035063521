import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { Therapist } from '@/views/all-therapists/interfaces/therapists.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';

export type Options = Omit<UseQueryOptions<unknown, DefaultError, Therapist | null>, 'queryKey' | 'queryFn'>;

export const useTherapistQuery = (id?: string, options?: Options) => {
  const _query = async (): Promise<Therapist | null> => {
    if (id) {
      const { data } = await axiosInterceptorInstance.get(`therapists/${id}`);
      return data;
    } else {
      return null;
    }
  };

  return useQuery({
    queryKey: ['therapists', id],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
